<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8">
        <div class="row mt-4">
          <div>
            <div class="row">
              <!-- Margin top sur petit ecran seulement -->
              <div class="col-md-4 mb-4">
                <default-info-card :classIcon="account.classIcon" :title="account.title" :desc="account.desc"
                  :price="account.price" />
              </div>
              <div class="col-md-4 mb-4">
                <default-info-card :classIcon="salary.classIcon" :title="salary.title" :desc="salary.desc"
                  :price="salary.price" />
              </div>
              <div class="col-md-4 mb-4">
                <default-info-card :classIcon="benefits.classIcon" :title="benefits.title" :desc="benefits.desc"
                  :price="benefits.price" />
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-4">
            <payment-card />
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <invoice-card class="mt-4" :invoices="invoices" />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-7">
        <billing-card />
      </div>
      <div class="col-md-5">
        <transaction-card />
      </div>
    </div> -->
  </div>
</template>

<script>
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
import PaymentCard from "./components/PaymentCardPS.vue";
import InvoiceCard from "./components/InvoiceCardPS.vue";
// import BillingCard from "./components/BillingCard.vue";
// import TransactionCard from "./components/TransactionCard.vue";
import apiClient from "@/apiService.js";
// import ArgonButton from "@/components/ArgonButton.vue";
import { useToast } from "vue-toastification";
// import router from "@/router/index.js";

export default {
  name: "Billing",
  components: {
    DefaultInfoCard,
    PaymentCard,
    InvoiceCard,
    // BillingCard,
    // TransactionCard,
    // ArgonButton
  },
  data() {
    return {
      account: {
        classIcon: "text-white fas fa-user-circle",
        title: "Compte",
        desc: "Profit Sharing : 40%",
        price: "Inactif",
      },

      salary: {
        classIcon: "text-white fas fa-wallet",
        title: "Paiement",
        desc: "A jour",
        price: "0 USDT",
      },

      benefits: {
        classIcon: "text-white fas fa-chart-line",
        title: "Bénéfices Totals Nets",
        desc: "Depuis le début",
        price: "0 USDT",
      },
      invoices: [],
    };
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    async getInvoices() {
      try {
        const response = await apiClient.get("/profitSharing/getInvoices");
        this.invoices = response.data;
        this.invoices = this.invoices.slice(0, 5);

      } catch (error) {
        this.toast.error("Erreur lors de la récupération des factures");
      }
    },
    async getAccountInformations() {
      try {
        const response = await apiClient.get("/profitSharing/getAccountInformations");
        this.account.price = response.data.actif;
        this.account.desc = "Profit Sharing : " + response.data.profit_sharing_pct + "%";

        this.salary.desc = response.data.invoices;
        this.salary.price = response.data.unpaid_invoices + " USDT";

        this.benefits.price = response.data.total_benefits + " USDT";

      } catch (error) {
        this.toast.error("Erreur lors de la récupération des informations du compte");
      }
    },
  },
  mounted() {
    this.getInvoices();
    this.getAccountInformations();
  },
  beforeMount() {
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>
