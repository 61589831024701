<template>
    <div class="mb-4 card">
      <div class="p-3 card-body">
        <div class="d-flex" :class="directionReverse ? reverseDirection : ''">
          <div>
            <div class="text-center icon icon-shape"
             :class="`${iconBackground} ${this.$store.state.isRTL ? 'border-radius-md' : 'border-radius-2xl'}`">
              <i class="text-lg opacity-10" :class="iconClass" aria-hidden="true"></i>
            </div>
          </div>
          <div :class="contentClass">
            <div class="numbers" v-if="this.$store.state.isRTL">
              <p class="mb-0 text-sm text-uppercase font-weight-bold" :class="titleColor">{{ title }}</p>
              <h5 class="font-weight-bolder mt-2" :class="valueColor">
                <template v-if="values && values.length">
                  <div v-for="(val, index) in values" :key="index">
                    <template v-if="val.amount !== undefined">
                      {{ val.strategy }}: {{ val.amount }} <br />
                    </template>
                    <template v-else>
                      {{ val.strategy }} <br />
                    </template>
                  </div>
                </template>
                <template v-else>
                  {{ value }}
                </template>
              </h5>
              <span class="text-sm" :class="percentageColor">{{ percentage }}</span> 
              <span class="font-weight-light text-sm"> {{ detail }}</span>
            </div>
            <div class="numbers" v-else>
              <p class="mb-0 text-sm text-uppercase font-weight-bold" :class="titleColor">{{ title }}</p>
              <h5 class="font-weight-bolder mt-2" :class="valueColor">
                <template v-if="values && values.length">
                  <div v-for="(val, index) in values" :key="index">
                    <template v-if="val.amount !== undefined">
                      {{ val.strategy }}: {{ val.amount }} <br />
                    </template>
                    <template v-else>
                      {{ val.strategy }} <br />
                    </template>
                  </div>
                </template>
                <template v-else>
                  {{ value }}
                </template>
              </h5>
              <span class="text-md" :class="percentageColor">{{ percentage }}</span> 
              {{ detail }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
export default {
  name: "card",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
    };
  },
  props: {
    directionReverse: Boolean,
    title: {
      type: String,
      required: true,
    },
    detail: {
      type: String,
      required: true,
    },
    titleColor: {
      type: String,
    },
    value: {
      required: true,
    },
    values: {
      type: Array,
      default: () => [],
    },
    valueColor: {
      type: String,
    },
    percentage: String,
    iconClass: {
      type: String,
      required: true,
    },
    percentageColor: {
      type: String,
      default: "text-success",
    },
    iconBackground: {
      type: String,
      default: "bg-white",
    },
    contentClass: {
      type: String,
    },
  },
};
</script>

  