<template>
  <div class="card mt-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Méthode de paiement</h6>
        </div>

      </div>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <!-- Switch pour activer le paiement automatique -->
        <div class="col-12 d-flex align-items-center mb-4">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="autoPaymentSwitch"
              v-model="account.automatic_payment" />
            <label class="form-check-label" for="autoPaymentSwitch">
              Activer le paiement automatique
            </label>
          </div>
          <i class="fas fa-info-circle ms-2 text-muted cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top"
            title="Si activé, le paiement sera automatiquement effectué."></i>
        </div>

        <!-- Champs supplémentaires si le paiement automatique est activé -->
        <div class="col-12">
          <div v-if="account.automatic_payment && account.valid_key" class="row mt-3">
            <div class="col-md-7">
              <label for="api-key-input-show" class="form-control-label">Clé Api Publique</label>
              <input id="api-key-input-show" class="form-control form-group" type="text" v-model="account.api_key_main"
                disabled />
            </div>
            <div class="col-md-12 d-flex align-items-center mt-3">
              <argon-button color="danger" size="sm" type="submit" @click.prevent="deleteApiKeyPS()">
                Supprimer
              </argon-button>

            </div>
          </div>

          <div class="row mt-3" v-if="!account.valid_key && account.automatic_payment">
            <div class="col-md-6">
              <label for="api-key-input" class="form-control-label">Clé API Publique</label>
              <input id="api-key-input" class="form-control form-group" type="text" v-model="account.api_key_main"
                required />
            </div>
            <div class="col-md-6">
              <label for="api-secret-key-input" class="form-control-label">Clé secrète</label>
              <input id="api-secret-key-input" class="form-control form-group" type="password"
                v-model="account.api_secret_key_main" required />
            </div>
            <div class="col-md-6">
              <label for="pass_phrase_main-input" class="form-control-label">Phrase secrète</label>
              <input id="pass_phrase_main-input" class="form-control form-group" type="password" v-model="account.pass_phrase_main"
                required />
            </div>
            <argon-button color="primary" size="sm" type="submit" @click="addApiKeyPS()">
              Ajouter
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import img1 from "@/assets/img/logos/mastercard.png";
import img2 from "@/assets/img/logos/visa.png";
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
// import router from "@/router/index.js";

export default {
  name: "PaymentCard",
  components: {
    ArgonButton,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      img1,
      img2,
      account:
      {
        api_key_main: "",
        valid_key: false,
        automatic_payment: false,
        api_secret_key_main: "",
        pass_phrase_main: "",
      },
    };
  },
  methods: {
    async getUserApiKeys() {
      try {
        const response = await apiClient.get("/profitSharing/getApiKeysPS");
        if (response.status === 200) {
          this.account.api_key_main = response.data.api_key_main;
          this.account.valid_key = response.data.valid_key;
          this.account.automatic_payment = response.data.automatic_payment;
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    async addApiKeyPS() {
      try {
        const response = await apiClient.post("/profitSharing/addApiKeysPS", {
          api_key_main: this.account.api_key_main,
          api_secret_key_main: this.account.api_secret_key_main,
          pass_phrase_main: this.account.pass_phrase_main,
          automatic_payment: this.account.automatic_payment,
        });
        if (response.status === 200) {
          this.toast.success("Clé API ajoutée avec succès");
          this.getUserApiKeys();
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    async deleteApiKeyPS() {
      try {
        const response = await apiClient.delete("/profitSharing/deleteApiKeysPS");
        if (response.status === 200) {
          this.toast.success("Clé API supprimée avec succès");
          this.getUserApiKeys();
          // On met les champs à vide
          this.account.api_key_main = "";
          this.account.api_secret_key_main = "";
          this.account.pass_phrase_main = "";
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
  },
  beforeMount() {
    this.getUserApiKeys();
  },
};
</script>
