import axios from "axios";
import router from "./router"; // Assurez-vous que le chemin est correct
import store from "./store"; // Assurez-vous que le chemin est correct

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL_DEV;
} else {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL_PROD;
}

axios.defaults.withCredentials = true; // Ajout pour gérer les cookies

axios.interceptors.response.use(
    response => response,
    error => {
        if (!error.response) {
            // Si l'erreur n'a pas de réponse, c'est probablement un problème de réseau ou API inaccessible
            store.commit('setApiError', true);
        } else if (error.response.status === 401) {
            // Gestion des erreurs 401 (non autorisé)
            router.push("/signin");
        }
        return Promise.reject(error);
    }
);

// export async function checkApiStatus() {
//     try {
//         await axios.get("/ping");
//         store.commit('setApiError', false);
//     } catch (error) {
//         store.commit('setApiError', true);
//         router.push("/maintenance");
//     }
// }

export default axios; // Exporter axios comme apiClient
