<script>
export default {
  mounted() {
    this.sidenavTypeOnResize();
    window.addEventListener('resize', this.sidenavTypeOnResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.sidenavTypeOnResize);
  },
  methods: {
    sidenavTypeOnResize() {
      const sidenavElement = document.querySelector('.sidenav');
      if (sidenavElement) {
        sidenavElement.classList.add('new-class');
      } else {
        console.warn('sidenav element not found');
      }
    }
  }
}
</script>
