<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
    <div class="container tutorial-container mt-7">
        <div>
            <h2 class="text-center">Tutoriels</h2>
            <p class="text-center fs-5"> => <a href="/tutoBot">Fonctionnement des services</a></p>
            <section>
                <h2 class="text-center">S'inscrire sur Bitget</h2>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Bitget est une plateforme de trading de crypto-monnaies qui permet aux utilisateurs d'acheter,
                            vendre et échanger diverses devises numériques. Elle offre des fonctionnalités telles que le
                            trading sur marge, les contrats à terme, et d'autres outils pour les investisseurs intéressés
                            par le marché des crypto-monnaies. Il est recommandé de vérifier les informations les plus
                            récentes, car les services et caractéristiques des plateformes peuvent évoluer au fil du temps.
                        </p>
                        <p>Une cryptomonnaie est une forme d'argent virtuel sécurisée par des techniques informatiques,
                            fonctionnant en dehors du contrôle des gouvernements. Elle utilise la technologie de la
                            blockchain pour enregistrer les transactions de manière transparente et décentralisée.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/bitget.png" alt="logo_Bitget" class="image-width">
                    </div>
                </div>

                <div class="div-flex">
                    <div class="div-50">
                        <p>Entrez votre email et un mot de passe robuste.</p>
                        <p>En cliquant sur <a href="https://share.bitget.com/u/3FW12B9V" target="_blank" class="bold lien">ce lien</a>, vérifiez que vous avez le code de parrainage <span class="bold">ZFYVY6LH</span> afin d'être
                            rattaché à notre service et de bénéficier des 15% de réduction sur chaque transactions.</p>
                        <p>Cochez la case en acceptant les coditions générales d'utilisations</p>
                        <p>Cliquez sur créer un compte.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/register-bitget.png" alt="logo_Bitget" class="image-width">
                    </div>
                </div>
            </section>

            <section>
                <h2 class="text-center">Vérification d'identité (KYC)</h2>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Cliquez sur l'avatar en haut à droite, puis sur <span class="bold">Vérification de l'identité</span>.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/menu.png" alt="logo_Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Cliquez sur <span class="bold">Vérifier maintenant</span>.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/kyc-1.png" alt="logo_Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Veuillez préciser le pays qui a émis votre pièce d'identité et sélectionner le type de document
                            que vous comptez utiliser pour vérifier votre identité. La gamme de documents acceptés dépend du
                            pays émetteur de votre pièce d'identité.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/kyc-2.png" alt="logo_Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Saisissez vos informations personnelles telles qu'elles apparaissent sur votre pièce d'identité.
                        </p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/kyc-3.png" alt="logo_Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Il vous sera nécessaire de télécharger des images à la fois du côté recto et du côté verso de la
                            pièce d'identité que vous avez sélectionnée.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/kyc-4.png" alt="logo_Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Assurez-vous que les images soient en haute résolution et totalement visibles, sans aucune
                            coupure.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/kyc-5.png" alt="logo_Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Accordez l'autorisation d'accès à la caméra afin de réaliser un selfie en temps réel. Prenez une
                            photo de votre visage sur un fond clair, en vous assurant que votre visage soit positionné à
                            l'intérieur de la forme ovale.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/kyc-6.png" alt="logo_Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Une fois votre selfie soumis, vous recevrez une notification confirmant que vos informations de
                            vérification KYC ont été transmises avec succès. Par la suite, Bitget procédera à une
                            vérification rapide de vos données.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/kyc-7.png" alt="logo_Bitget" class="image-width">
                    </div>
                </div>
                <p class="text-center">Dès que votre compte aura été vérifié avec succès, Bitget vous enverra un e-mail et
                    une notification push. Une fois la vérification KYC de niveau 1 complétée, vous pourrez profiter de tous
                    les services et produits Bitget sans aucune restriction !</p>
            </section>
            <section>
                <h2 class="text-center">Effectuer un dépôt</h2>
                <p>Après la vérification de compte KYC, vous pouvez effectuer votre dépôt.</p>
                <p>Vous pouvez effectuer deux types de dépôts : les dépôts en fiat ou en crypto-monnaies. Si vous souhaitez acquérir des crypto-monnaies avec vos fonds propres, suivez la procédure 1. Si vous avez déjà de la crypto-monnaie que vous souhaitez envoyer sur Bitget, suivez la procédure 2.</p>
                <h3>Procédure 1 - Acquérir des cryptomonnaies avec des devises fiat</h3>
                <div class="div-flex">
                    <div class="div-50">
                        <p>À partir de la page d'accueil du site web de Bitget, connectez-vous et survolez l'onglet "<span class="bold">Acheter des cryptos</span>", puis sélectionnez l'option de dépôt qui correspond à vos préférences.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/buy-1.png" alt="Achat Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Cliquez sur <span class="bold">USD</span> (dollar) pour sélectionner <span class="bold">EUR</span> (euros).</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/buy-2.png" alt="Achat Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Indiquez dans "<span class="bold">Je vais payer</span>" le montant que vous souhaitez déposer.
                        </p>
                        <p>On vous indique par la suite, dans " <span class="bold">Je vais recevoir</span>" le montant que
                            vous aller obtenir.</p>
                        <p>Cliquez ensuite sur <span class="bold">Acheter USDT</span> et finalisez le paiement.</p>
                        <p>Vous pouvez payer par carte bancaire et/ou ApplePay/GooglePay.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/buy-3.png" alt="Achat Bitget" class="image-width">
                    </div>
                </div>
                <h3>Procédure 2 - Déposer ses cryptos d'une autre plateforme</h3>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Pour déposer vos crpyto monnaies sur Bitget, appuyez sur le symbole du portefeuille, puis sur
                            <span class="bold">Dépôt</span>.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/buy-4.png" alt="Achat Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Indiquez dans <span class="bold">Sélectionnez une crypto</span> la crypo monnaie que vous
                            souhaitez déposer. Ici l'USDT.</p>
                        <p>Dans <span class="bold">Sélectionnez un réseau</span> indiquez le bon réseau sur lequel vous
                            allez déposer vos cryptos. Vérifiez deux fois si nécessaire que le réseau est celui souhaité.
                            Ceci est primordial pour bien recevoir votre dépôt.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/buy-5.png" alt="Achat Bitget" class="image-width">
                    </div>
                </div>
                <p class="text-center">Après validation de votre dépôt. Vous recevrez une notification push et par mail de
                    la part de Bitget.</p>
            </section>
            <section>
                <h2 class="text-center">Créer un sous-compte</h2>
                <h3 class="text-center">Définition d'un sous-compte</h3>
                <p class="text-center">Les sous-comptes offrent la possibilité de gérer des comptes Bitget autonomes de
                    manière indépendante sous un compte principal unique. Ils sont pratiques pour isoler des positions, des
                    actifs ou des accès spécifiques</p>

                <div class="div-flex">
                    <div class="div-50">
                        <p>Indiquez dans <span class="bold">Sélectionnez une crypto</span> la crypo monnaie que vous
                            souhaitez déposer. Ici l'USDT.</p>
                        <p>Dans <span class="bold">Sélectionnez un réseau</span> indiquez le bon réseau sur lequel vous
                            allez déposer vos cryptos. Vérifiez deux fois si nécessaire que le réseau est celui souhaité.
                            Ceci est primordial pour bien recevoir votre dépôt.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/buy-5.png" alt="Achat Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Cliquez sur l'icone de l'avatar, puis dans <span class="bold">Sous-comptes</span></p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/scompte-1.png" alt="Sous compte Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Sous la section <span class="bold">Gestion des sous-comptes</span>, cliquez sur <span
                                class="bold">Créer un sous-compte</span>.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/scompte-2.png" alt="Sous compte Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Pour commencer, cliquez sur <span class="bold">Utiliser un e-mail personnel</span></p>
                        <p>Dans <span class="bold">Nom du sous-comtpte</span>, donnez un nom à celui-ci.</p>
                        <p>Dans <span class="bold">Email</span>, indiquez un email différent de votre compte actuel.</p>
                        <p>Créer un mot de passe pour ce sous-compte.</p>
                        <p>Indiquez dans <span class="bold">Remarques</span> les informations qui vous semble pertinente
                            (optionnel)</p>
                        <p>Quand tout est ok, cliquez sur <span class="bold">Confirmer</span>.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/scompte-3.png" alt="Sous compte Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Appuyez sur <span class="bold">Envoyer</span> afin de recevoir le code de validation sur
                            l'adresse mail que vous avez indiquez.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/scompte-4.png" alt="Sous compte Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Après avoir noté le code, appuyez sur <span class="bold">Confirmer</span>.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/scompte-5.png" alt="Sous compte Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Vôtre sous-compte étant créé, appuyez sur "<span class="bold">d'installation</span>".</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/scompte-6.png" alt="Sous compte Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Vérifiez que vous avez le même paramétrage que la photo ci-contre.</p>
                        <p>Si tout est ok, cliquez sur <span class="bold">API Keys</span></p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/scompte-7.png" alt="Sous compte Bitget" class="image-width">
                    </div>
                </div>

                <div class="div-flex">
                    <div class="div-50">
                        <p>Cliquez sur <span class="bold">Créer une clé API</span>.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/api-1.png" alt="API Bitget" class="image-width">
                    </div>
                </div>

                <div class="div-flex">
                    <div class="div-50">
                        <p>Puis sur <span class="bold">Clé API générée par le système</span>.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/api-2.png" alt="API Bitget" class="image-width">
                    </div>
                </div>

                <div class="div-flex">
                    <div class="div-50">
                        <p>Nommez votre API dans <span class="bold">Remarques</span>.</p>
                        <p>Créez un mot de passe robuste pour votre clé API, indiquez-le dans <span class="bold">Phrase
                                secrète (token d'API)</span>. Notez-le précieusement, vous en aurez besoin pour automatiser
                            le connexion avec nos logiciels.</p>
                        <p>Dans les paramètres, cochez les cases comme le photo ci-contre. Soit <span
                                class="bold">Ordres</span> et <span class="bold">Avoirs</span> dans <span
                                class="bold">Futures</span>.</p>
                        <p>Pour les paramètres d'autorisation : <span class="bold">Lecture-écriture</span></p>
                        <p>Lorsque tout est ok, cliquez sur <span class="bold">Suivant</span>.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/api-3.png" alt="API Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Appuyez sur <span class="bold">Envoyer</span> afin de recevoir les codes de validation par mail
                            et sms.</p>
                        <p>Après les avoir indiquez, appuyez sur <span class="bold">Confirmer</span>.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/api-4.png" alt="API Bitget" class="image-width">
                    </div>
                </div>

                <div class="div-flex">
                    <div class="div-50">
                        <p>Votre clé API est crée, vous avez maintenant vos clés d'accès.</p>
                        <p><span class="bold">Gardez-les précieusement !</span> Vous en aurez besoin pour automatiser la
                            connexion avec nos logiciels.</p>
                        <p>Après avoir noté vos codes, appuyez sur <span class="bold">Confirmer</span>.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/api-5.png" alt="API Bitget" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Connectez-vous sur le <a href="https://www.nexifyr.fr/my-robot" target="_blank"
                                class="lien bold">back-office</a>, puis allez dans la section <span class="bold">Mon
                                robot</span>.</p>
                        <p>Indiquez dans <span class="bold">Api Public Key</span> votre <span class="bold">Clé API
                                d'accès</span> obtenu sur Bitget.</p>
                        <p>Dans <span class="bold">Secret Key</span> notez votre <span class="bold">Clé secrète</span>.</p>
                        <p>Puis dans <span class="bold">PassPhrase</span>, la <span class="bold">phrase secrète (token
                                d'API)</span> que vous avez créer lors de la configuration de l'API.</p>
                        <p>Pour finir, appuyez sur <span class="bold">Ajouter</span>.</p>

                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/bo-1.png" alt="Back Office" class="image-width">
                    </div>
                </div>
                <div class="div-flex">
                    <div class="div-50">
                        <p>Vous avez, sur le <a href="/dashboard" class="lien bold">back office</a>, les informations propres à votre compte.</p>
                        <p>Dans la vignette <span class="bold">Solde du compte</span> : vous devez visualiser le montant de votre compte. S'il n'y a pas de montant, cela veut dire que le processus d'inscription ne s'est pas bien finalisé de votre côté.</p>
                        <p>Dans la vignette <span class="bold">Status robot</span> : vous avez le statut de votre compte vous indiquant toutes les informations nécessaires.</p>
                    </div>
                    <div class="div-50 text-center">
                        <img src="../assets/img/tutorial/account_status.png" alt="Dashboard" class="image-width">
                    </div>
                </div>
                <p>Si vous ne voyez pas le montant de votre balance dans la page Dashboard, cela peut être dû à plusieurs raisons.</p>
                <p>Vérifiez si les clés API que vous avez rentrées sont valides.</p>
                <p>Si vous avez bien créé un sous-compte, vérifiez si vous n'avez pas laissé vos fonds sur votre compte principal. Si c'est le cas, transférez vos fonds sur votre sous-compte.</p>
                <p>Vérifiez que vos fonds sont bien sûr le compte Futures (USDT-M) et non sur le compte SPOT. Si c'est le cas, effectuez un transfert sur le bon compte.</p>
                <p>En cas de doute, n'hésitez pas à contacter le support. Soit par l'assistance sur le <a href="/support" class="lien bold">back office</a>, ou via l'application <a  href="https://t.me/nexifyr" target="_blank" class="lien bold">Telegram</a>.</p>
            </section>
        </div>
        <app-footer />
    </div>
</template>
 
<script>

import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";

export default {
    name: "Tutorial",
    components: {
        Navbar,
        AppFooter,
    },
    // mounted() {
    //     this.$store.state.isAbsolute = true;
    // },
    // beforeMount() {
    //     this.$store.state.showNavbar = false;
    //     this.$store.state.showFooter = false;
    //     this.$store.state.hideConfigButton = true;
    // },
    // beforeUnmount() {
    //     this.$store.state.isAbsolute = false;
    //     this.$store.state.showNavbar = false;
    //     this.$store.state.showFooter = false;
    //     this.$store.state.hideConfigButton = false;
    // }
    created() {
        this.$store.state.hideConfigButton = true;
        this.$store.state.showNavbar = false;
        this.$store.state.showSidenav = false;
        this.$store.state.showFooter = false;
    },
    beforeUnmount() {
        this.$store.state.hideConfigButton = false;
        this.$store.state.showNavbar = true;
        this.$store.state.showSidenav = true;
        this.$store.state.showFooter = true;
    },
};


</script>
<style scoped>

body {
    font-family: "DM Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.div-flex {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    text-align: justify;
}

.div-50 {
    width: 50%;
    text-align: justify;
}

.image-width {
    width: 50%;
    margin: auto;
}

.text-center {
    text-align: center;
}

.bold {
    font-weight: bold;
}
.underline{
    text-decoration: underline;
}

.lien {
    color: blue;
    text-decoration: underline;
}

.tutorial-container {
    margin-top: 5%;
}

.profit-sharing {
    list-style: outside none none; /* Enlève les puces par défaut */
    display: inline-block; /* Permet de centrer la liste dans son conteneur */
    text-align: left; /* Garde le texte des items aligné à gauche */
    padding-left: 20px; /* Ajoute de l'espace pour les puces personnalisées */
}

.profit-sharing li:before {
    content: "•"; /* Crée une puce personnalisée */
    font-size: 1.5em; /* Taille de la puce */
    color: black; /* Couleur des puces */
    display: inline-block; /* Nécessaire pour aligner les puces avec le texte */
    width: 1em; /* Espace avant le texte */
    margin-left: -1em; /* Décale les puces à gauche du texte */
}

/* Media query pour les écrans jusqu'à 768px de large */
@media (max-width: 768px) {
  .tutorial-container {
    margin-top: 10%; 
  }
  .div-flex{
    flex-direction: column-reverse;
  }
  .div-50{
    width: 100%;
    margin: 10px 0;
    text-align: center;
  }
  p, h3, h2, h1{
    text-align: center;
  }
}

</style>