<template>
  <div class="card pb-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Factures</h6>
        </div>
        <!--<div class="col-6 text-end">
          <argon-button color="success" size="sm" variant="outline">View All</argon-button>
        </div>-->
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li v-for="(invoice, index) in invoices" :key="index" class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">{{ invoice.date }}</h6> <!-- Assurez-vous que les champs correspondent à ceux de votre objet de facture -->
            <span class="text-xs">{{ invoice.id }}</span>
          </div>
          <div class="d-flex align-items-center text-sm">
            ${{ invoice.amount }}
            <!--<button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i> PDF
            </button>-->
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "invoice-user",
  components: {
   // ArgonButton,
  },
  props: {
    invoices: Array, 
  },
};
</script>
