<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <card
              :title="stats.strategySafe.title"
              :value="stats.strategySafe.value"
              :percentage="stats.strategySafe.percentage"
              :iconClass="stats.strategySafe.iconClass"
              :iconBackground="stats.strategySafe.iconBackground"
              :detail="stats.strategySafe.detail"
              :percentageColor="stats.strategySafe.percentageColor"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <card
              :title="stats.strategyAggressive.title"
              :value="stats.strategyAggressive.value"
              :percentage="stats.strategyAggressive.percentage"
              :iconClass="stats.strategyAggressive.iconClass"
              :iconBackground="stats.strategyAggressive.iconBackground"
              :detail="stats.strategyAggressive.detail"
              :percentageColor="stats.strategyAggressive.percentageColor"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <card
              :title="stats.users.title"
              :value="stats.users.value"
              :percentage="stats.users.percentage"
              :iconClass="stats.users.iconClass"
              :iconBackground="stats.users.iconBackground"
              :detail="stats.users.detail"
              :percentageColor="stats.users.percentageColor"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <card
              :title="stats.clients.title"
              :value="stats.clients.value"
              :percentage="stats.clients.percentage"
              :iconClass="stats.clients.iconClass"
              :iconBackground="stats.clients.iconBackground"
              :percentageColor="stats.clients.percentageColor"
              :detail="stats.clients.detail"
              directionReverse
            ></card>
          </div>
          <!--
          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <card
              :title="stats.sales.title"
              :value="stats.sales.value"
              :percentage="stats.sales.percentage"
              :iconClass="stats.sales.iconClass"
              :iconBackground="stats.sales.iconBackground"
              :detail="stats.sales.detail"
              directionReverse
            ></card>
          </div> -->
        </div>
        <div class="row">
          <div class="col-lg-6 mb-lg">
            <!-- line chart -->
            <div class="card">
              <DashboardAdminMoneyLineChartSafe :arrayValue="stats.TotalMoneyDailyGraphSafe" :adminPage="true" />
            </div>
          </div>
          <div class="col-lg-6 mb-lg">
            <!-- line chart -->
            <div class="card">
              <DashboardAdminMoneyLineChartAggressive :arrayValue="stats.TotalMoneyDailyGraphAggressive" :adminPage="true" />
            </div>
          </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-5">
              <categories-card />
            </div>
            <div class="col-lg-7">
              <carousel />
            </div>
          <!-- <div class="col-lg-7 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">Sales by Country</h6>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center">
                  <tbody>
                    <tr v-for="(sale, index) in sales" :key="index">
                      <td class="w-30">
                        <div class="px-2 py-1 d-flex align-items-center">
                          <div>
                            <img :src="sale.flag" alt="Country flag" />
                          </div>
                          <div class="ms-4">
                            <p class="mb-0 text-xs font-weight-bold">Country:</p>
                            <h6 class="mb-0 text-sm">{{ sale.country }}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Sales:</p>
                          <h6 class="mb-0 text-sm">{{ sale.sales }}</h6>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Value:</p>
                          <h6 class="mb-0 text-sm">{{ sale.value }}</h6>
                        </div>
                      </td>
                      <td class="text-sm align-middle">
                        <div class="text-center col">
                          <p class="mb-0 text-xs font-weight-bold">Bounce:</p>
                          <h6 class="mb-0 text-sm">{{ sale.bounce }}</h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> -->
        
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/examples/Cards/Card.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import DashboardAdminMoneyLineChartSafe from "../examples/Charts/DashboardAdminMoneyLineChartSafe.vue";
import DashboardAdminMoneyLineChartAggressive from "../examples/Charts/DashboardAdminMoneyLineChartAggressive.vue";

import Carousel from "./components/Carousel.vue";
import CategoriesCard from "./components/CategoriesCard.vue";
import apiClient from "@/apiService.js";
// import { useToast } from "vue-toastification";
// import router from "@/router/index.js";

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";

export default {
  name: "admin-dashboard",
  data() {
    return {
      stats: {
        strategySafe: {
          title: "Stratégie safe",
          value: "",
          weeklyValue: "",
          percentage: "",
          iconClass: "ni ni-money-coins",
          percentageColor: "",
          detail: "sur les 7 derniers jours",
          iconBackground: "bg-gradient-primary",
        },
        strategyAggressive: {
          title: "Stratégie agressive",
          value: "",
          weeklyValue: "",
          percentage: "",
          iconClass: "ni ni-money-coins",
          percentageColor: "",
          detail: "sur les 7 derniers jours",
          iconBackground: "bg-gradient-primary",
        },
        users: {
          title: "Total Utilisateurs",
          value: "",
          weeklyValue: "",
          percentage: "",
          iconClass: "ni ni-world",
          iconBackground: "bg-gradient-danger",
          percentageColor: "",
          detail: "sur les 7 derniers jours",
        },
        clients: {
          title: "Utilisateurs actifs",
          value: "",
          percentage: "",
          iconClass: "ni ni-paper-diploma",
          percentageColor: "text-warning",
          iconBackground: "bg-gradient-success",
          detail: " : En attente d'activation admin",
        },
        sales: {
          title: "Abonnements vendus",
          value: "$103,430",
          percentage: "+5%",
          iconClass: "ni ni-cart",
          iconBackground: "bg-gradient-warning",
          detail: "than last month",
        },
      },
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
      TotalMoneyDailyGraphSafe: [],
      TotalMoneyDailyGraphAggressive: [],
    };
  },
  components: {
    Card,
    // GradientLineChart,
    DashboardAdminMoneyLineChartSafe,
    DashboardAdminMoneyLineChartAggressive,
    Carousel,
    CategoriesCard,
  },
  // setup() {
  //     const toast = useToast();
  
  //     return { toast };
  // },
  methods: {
    async getAdminDashboard() {
      try {
        const response = await apiClient.get("/admin/getDashboardStats");
        if (response.status === 200) {
          this.stats.strategySafe.value = response.data.totalMoneyValueSafe + " $";
          this.stats.strategySafe.percentage = response.data.totalMoneyWeeklyPercentSafe + "%";
          this.stats.strategySafe.weeklyValue = response.data.totalMoneyWeeklyValueSafe;

          this.stats.strategyAggressive.value = response.data.totalMoneyValueAggressive + " $";
          this.stats.strategyAggressive.percentage = response.data.totalMoneyWeeklyPercentAggressive + "%";
          this.stats.strategyAggressive.weeklyValue = response.data.totalMoneyWeeklyValueAggressive;

          this.stats.users.value = response.data.users;
          this.stats.users.percentage = response.data.usersWeekPercent + "%";
          this.stats.users.weeklyValue = response.data.usersWeek;

          this.stats.clients.value = response.data.activeUsers;
          this.stats.clients.percentage = response.data.waitingAdminApproval;

          this.stats.TotalMoneyDailyGraphSafe = response.data.TotalMoneyDailyGraphSafe;
          this.stats.TotalMoneyDailyGraphAggressive = response.data.TotalMoneyDailyGraphAggressive;

          if (response.data.totalMoneyWeeklyPercentSafe >= 0) {
            this.stats.strategySafe.percentageColor = "text-success";
            this.stats.strategySafe.percentage = `+${this.stats.strategySafe.percentage}`;
          } else {
            this.stats.strategySafe.percentageColor = "text-danger";
          }

          if (response.data.totalMoneyWeeklyPercentAggressive >= 0) {
            this.stats.strategyAggressive.percentageColor = "text-success";
            this.stats.strategyAggressive.percentage = `+${this.stats.strategyAggressive.percentage}`;
          } else {
            this.stats.strategyAggressive.percentageColor = "text-danger";
          }

          if (response.data.usersWeekPercent >= 0) {
            this.stats.users.percentageColor = "text-success";
            this.stats.users.percentage = `+${this.stats.users.percentage}`;
          } else {
            this.stats.users.percentageColor = "text-danger";
          }
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
  },
  mounted() {
      this.getAdminDashboard();
  },
  beforeMount() {
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>
