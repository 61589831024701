<template>
  <div class="card card-profile">
    <img src="../../assets/img/bg-profile.jpg" alt="Image placeholder" class="card-img-top" />
    <div class="row justify-content-center">
      <div class="col-4 col-lg-4 order-lg-2">
        <div class="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
          <a href="javascript:;">
            <img
              src="../../assets/img/Avatar.webp"
              class="rounded-circle img-fluid border border-2 border-white"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="card-header text-center border-0 pt-0 pt-lg-2 pb-4 pb-lg-3">
      <div class="d-flex justify-content-between">
        <!-- <a href="/network" class="btn btn-sm btn-info mb-0 d-none d-lg-block">Parrainages</a> -->

        <a
          href="/my-robot"
          class="btn btn-sm btn-dark float-right mb-0 d-none d-lg-block"
        >Mon Robot</a>
      </div>
    </div>
    <!-- <div class="card-body pt-0">
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <div class="d-grid text-center">
              <span class="text-lg font-weight-bolder">22</span>
              <span class="text-sm opacity-8">Parrainages</span>
            </div>
            <div class="d-grid text-center mx-4">
              <span class="text-lg font-weight-bolder">10</span>
              <span class="text-sm opacity-8">Actifs</span>
            </div>
            <div class="d-grid text-center">
              <span class="text-lg font-weight-bolder">$</span>
              <span class="text-sm opacity-8">Revenus</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-4">
        <i> Sur les 7 derniers jours</i>
        <div class="h6 font-weight-300">
          Nouveaux Parrainages :
          <span class="font-weight-light"> 35</span>
        </div>
        <div class="h6 font-weight-300">
          Nouveaux Actifs :
          <span class="font-weight-light"> 12</span>
           | Revenus :
          <span class="font-weight-light"> $</span>
        </div>
        <hr />
        <i> Sur les 30 derniers jours</i>
        <div class="h6 font-weight-300">
          Nouveaux Parrainages :
          <span class="font-weight-light"> 35</span>
        </div>
        <div class="h6 font-weight-300">
          Nouveaux Actifs :
          <span class="font-weight-light"> 12</span>
            | Revenus :
          <span class="font-weight-light"> $</span>
        </div>
      </div>
    </div> -->
  </div> 
</template>

<script>
export default {
  name: "profile-card",
};
</script>