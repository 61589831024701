<template>
    <div class="edica-loader"></div>
    <navbar isBlur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow" v-bind:darkMode="true"
        isBtn="bg-gradient-success" />

    <div class="edica-header edica-landing-header">
        <div class="container">
            <div class="edica-landing-header-content mt-5">
                <div id="edicaLandingHeaderCarousel" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner" role="listbox">
                        <div class="carousel-item active">
                            <div class="row">
                                <div class="col-md-6 carousel-content-wrapper">
                                    <h1>Découvrez une stratégie brillante, testée et approuvé sur plusieurs années.</h1>
                                    <p>L'algorithme conçu pour vous révolutionner votre vision des cryptomonnaies,
                                        optimisant vos investissements avec précision et fiabilité</p>
                                    <div class="carousel-content-btns">
                                        <a href="/signup" class="btn btn-success">Démarrer l'aventure</a>
                                        <a href="/signin" class="btn btn-secondary">Se connecter</a>
                                    </div>
                                </div>
                                <div class="col-md-6 carousel-img-wrapper">
                                    <img src="../assets/img/home/Slider_1.png" alt="carousel-img" class="img-fluid"
                                        style="max-width: 60%;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <main class="mb-1">
        <section class="edica-landing-section edica-landing-about" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-md-6" data-aos="fade-up-right">
                        <h4 class="edica-landing-section-subtitle-alt">ABOUT US</h4>
                        <h2 class="edica-landing-section-title">+ de 2000 jours d'historiques</h2>
                        <p>Les stratégies ont été testées et/ou back test au cours des 2000 derniers jours, offrant des
                            résultats probants !</p>
                        <ul class="landing-about-list">
                            <li>Aucunes connaissances requises</li>
                            <li>Stratégie 100% automatique</li>
                            <li>Fonds disponibles instantannément</li>
                        </ul>
                    </div>
                    <div class="col-md-6" data-aos="fade-up-left">
                        <img src="../assets/img/home/phone-copy.png" width="468px" alt="about" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container">

                <div class="row">
                    <div class="col-lg-6 mb-lg" id="safe-chart">
                        <div class="card">
                            <DashboardAdminMoneyLineChartSafe :arrayValue="stats.TotalMoneyDailyGraphSafe"
                                :adminPage="false" />
                        </div>
                    </div>
                    <div class="col-lg-6 mb-lg" id="aggressive-chart">
                        <div class="card">
                            <DashboardAdminMoneyLineChartAggressive :arrayValue="stats.TotalMoneyDailyGraphAggressive"
                                :adminPage="false" />
                        </div>
                    </div>

                    <!-- On fait un select pour que l'investisseur choissise sa stratégie, son montant et sa date de début -->
                    <div class="col-lg-12 mt-5">
                        <div class="text-center">
                            <h3 class="h3 mb-3 text-center">Simulation de l’évolution d’un capital sur les stratégies
                            </h3>
                            <p>
                                Le graphique ci-dessous vous montre l’évolution de votre capital en fonction de la date
                                que vous avez choisie jusqu’à la date actuelle.
                            </p>
                            <p>
                                Veuillez saisir dans la case « Montant » la somme de votre choix, à condition qu’elle
                                soit au minimum de 300 €, soit la somme minimale requise pour nos stratégies.
                            </p>
                        </div>
                        <div class="card" id="strategy-simulation">
                            <div class="card-header">
                                <h5 class="h3 mb-0 text-center">Choisissez votre stratégie</h5>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="strategy">Stratégie</label>
                                        <div class="input-group">
                                            <select class="form-control" id="strategy" name="strategy"
                                                v-model="strategy" ref="strategy">
                                                <option value="safe">Safe</option>
                                                <option value="aggressive">Aggressive</option>
                                            </select>

                                        </div>
                                    </div>



                                    <div class="col-md-4">
                                        <label for="amount">Montant</label>
                                        <input type="number" class="form-control" id="amount" name="amount"
                                            placeholder="Montant" v-model="amount" min="300" @blur="validateAmount">
                                        <div v-if="amountError" class="text-danger">{{ amountError }}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="start_date">Date de début</label>
                                        <input type="date" class="form-control" id="start_date" name="start_date"
                                            v-model="start_date" :min="minStartDate" :max="maxStartDate"
                                            @input="validateStartDate(false)" @blur="validateStartDate(true)">
                                        <div v-if="startDateError" class="text-danger">{{ startDateError }}</div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- Chart Section -->
                <div class="col-lg-12 mt-4">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="h3 mb-0 text-center">Évolution du capital</h5>
                            <div class="text-center">
                                <p>Capital de départ: {{ amount }} €</p>
                                <p>Capital actuel: {{ capitalActual }} €</p>
                                <p>Gains totaux: {{ totalGains }} € | {{ totalGainsPercent }} %</p>
                            </div>
                        </div>
                        <div class="card-body">
                            <canvas id="chart-line-capital-simulation" class="chart-canvas" height="300"></canvas>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section class="edica-landing-section edica-landing-clients">
            <div class="container">
                <h4 class="edica-landing-section-subtitle-alt">UNE MULTITUDE DE CHOIX</h4>
                <div class="partners-wrapper">
                    <img src="../assets/img/home/Bitcoin_logo.svg.png" alt="client logo" data-aos="flip-right"
                        data-aos-delay="250" style="width: 10%;">
                    <img src="../assets/img/home/full-ethereum-logo-grey.png" alt="client logo" data-aos="flip-right"
                        data-aos-delay="500" style="width: 10%;">
                    <img src="../assets/img/home/avax-full-avalanche-logo.jpg" alt="client logo" data-aos="flip-right"
                        data-aos-delay="750" style="width: 10%;">
                    <img src="../assets/img/home/Dogecoin-Logo.png" alt="client logo" data-aos="flip-right"
                        data-aos-delay="1000" style="width: 10%;">
                    <img src="../assets/img/home/tron.png" alt="client logo" data-aos="flip-right" data-aos-delay="1250"
                        style="width: 10%;" class="logo-design-mobile">
                    <img src="../assets/img/home/solana.png" alt="client logo" data-aos="flip-right"
                        data-aos-delay="1500" style="width: 10%;">
                    <img src="../assets/img/home/bnb-logo.png" alt="client logo" data-aos="flip-right"
                        data-aos-delay="1750" style="width: 10%;" class="logo-design">
                    <img src="../assets/img/home/xrp-logo.png" alt="client logo" data-aos="flip-right"
                        data-aos-delay="2000" style="width: 10%;">
                    <img src="../assets/img/home/Cardano.svg.png" alt="client logo" data-aos="flip-right"
                        data-aos-delay="2250" style="width: 10%;" class="logo-design-mobile">
                    <img src="../assets/img/home/Polkadot_Logo.png" alt="client logo" data-aos="flip-right"
                        data-aos-delay="2500" style="width: 10%;">
                    <img src="../assets/img/home/full-litecoin-logo.png" alt="client logo" data-aos="flip-right"
                        data-aos-delay="2750" style="width: 10%;">
                    <img src="../assets/img/home/Chainlink_Logo_Blue.svg.png" alt="client logo" data-aos="flip-right"
                        data-aos-delay="3000" style="width: 10%;">
                </div>
            </div>
        </section>
        <section class="edica-landing-section edica-landing-services" id="projection">
            <div class="container">
                <h4 class="edica-landing-section-subtitle">Le service qu'on vous offre</h4>
                <h2 class="edica-landing-section-title">Un accompagnement<br> sur mesure.</h2>
                <div class="row">
                    <div class="col-md-6 landing-service-card" data-aos="fade-right">
                        <img src="../assets/img/home/picture.svg" alt="card img" class="img-fluid service-card-img">
                        <h4 class="service-card-title">Tutoriels de configuration</h4>
                        <p class="service-card-description">Quel que soit votre niveau d'expérience, nos tutoriels
                            détaillés
                            vous guident à travers chaque étape de configuration pour un accès simplifié à nos services.
                        </p>
                    </div>
                    <div class="col-md-6 landing-service-card" data-aos="fade-left">
                        <img src="../assets/img/home/internet.svg" alt="card img" class="img-fluid service-card-img">
                        <h4 class="service-card-title">Sûr et Fiable</h4>
                        <p class="service-card-description">Nos solutions sont garanties sécurisées grâce à des
                            connexions
                            HTTPS fiables, assurant ainsi une confidentialité accrue grâce à des échanges cryptés.</p>
                    </div>
                    <div class="col-md-6 landing-service-card" data-aos="fade-right">
                        <img src="../assets/img/home/goal.svg" alt="card img" class="img-fluid service-card-img">
                        <h4 class="service-card-title">Rapide. Instantanée.</h4>
                        <p class="service-card-description">Nos puissants serveurs offrent des prises de positions
                            extrêmement précises et des résultats fiables, garantissant ainsi une expérience inégalée.
                        </p>
                    </div>
                    <div class="col-md-6 landing-service-card" data-aos="fade-left">
                        <img src="../assets/img/home/chat-bubble.svg" alt="card img" class="img-fluid service-card-img">
                        <h4 class="service-card-title">Support 7j/7</h4>
                        <p class="service-card-description">Nous sommes présents à vos côtés 7j/7 pour vous assister
                            dans
                            toutes vos démarches.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="edica-landing-section edica-landing-testimonials" data-aos="fade-up" id="reviews">
            <div class="container">
                <div id="edicaLandingTestimonialCarousel" class="carousel slide landing-testimonial-carousel"
                    data-ride="carousel">
                    <div class="text-center py-4">
                        <img src="../assets/img/home/quote.svg" alt="quote">
                    </div>
                    <div class="carousel-inner" role="listbox">
                        <div v-for="(testimonial, index) in testimonials" :key="`carousel-item-${index}`"
                            class="carousel-item" :class="{ active: index === activeIndex }">
                            <blockquote class="testimonial">
                                <p>{{ testimonial.text }}</p>
                            </blockquote>
                        </div>
                    </div>

                    <ol class="carousel-indicators">
                        <li v-for="(testimonial, index) in testimonials" :key="`name-${index}`"
                            @click="setActiveIndex(index)">
                            <img :src="testimonial.avatar" alt="avatar">
                            <div class="user-details" v-if="index === activeIndex">
                                <h6>{{ testimonial.name }}</h6>
                                <p>{{ testimonial.position }}</p>
                            </div>
                        </li>
                    </ol>

                </div>
            </div>
        </section>


        <div class="container" id="faq">
            <section class="edica-about-faq py-5">
                <h2 class="faq-title" data-aos="fade-up">FAQ</h2>
                <p class="faq-section-text" data-aos="fade-up" data-aos-delay="100">Foire Aux Questions.</p>
                <div class="accordion" id="edicaAboutFaqCollapse" role="tablist" aria-multiselectable="true">
                    <div class="card" v-for="(faq, index) in faqs" :key="faq.id" :data-aos="fade - up"
                        :data-aos-delay="100 * index">
                        <div class="card-header" role="tab" :id="`heading${index}`">
                            <h5 class="mb-0">
                                <a data-toggle="collapse" :data-parent="'#edicaAboutFaqCollapse'" :href="'#' + faq.id"
                                    @click.prevent="toggleFaq(faq)" :aria-expanded="faq.open.toString()">
                                    <p class="fw-bold">{{ faq.question }}</p>
                                </a>
                            </h5>
                        </div>
                        <div :id="faq.id" class="collapse" :class="{ 'show': faq.open }" role="tabpanel"
                            :aria-labelledby="`heading${index}`">
                            <div class="card-body">
                                <p>{{ faq.answer }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="container">
            <div class="row" id="contact">
                <div class="col-lg-11 mx-auto">
                    <h1 class="edica-page-title" data-aos="fade-up">Contact</h1>
                    <p style="font-size: 30px;">Une question ? Remplissez ce formualire de contact et nous reviendrons
                        vers
                        vous
                    </p>
                    <section class="edica-contact">
                        <form @submit.prevent="submitForm">
                            <div class="row">
                                <div class="col-md-12 contact-form-wrapper">
                                    <div class="row">
                                        <div class="form-group col-md-6" data-aos="fade-up">
                                            <label for="name">NOM</label>
                                            <input type="text" class="form-control" id="name" name="name"
                                                placeholder="Votre nom complet" v-model="contactForm.name">
                                        </div>
                                        <div class="form-group col-md-6" data-aos="fade-up">
                                            <label for="phone">TELEPHONE</label>
                                            <input type="text" class="form-control" id="phone" name="phone"
                                                placeholder="Numéro de téléphone" v-model="contactForm.phone">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-6" data-aos="fade-up" data-aos-delay="100">
                                            <label for="email">EMAIL</label>
                                            <input type="email" class="form-control" id="email" name="email"
                                                placeholder="Adresse email" v-model="contactForm.email">
                                        </div>
                                        <div class="form-group col-md-6" data-aos="fade-up" data-aos-delay="100">
                                            <label for="subject">SUJET</label>
                                            <input type="text" class="form-control" id="subject" name="subject"
                                                placeholder="Sujet du messsage" v-model="contactForm.subject">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-12" data-aos="fade-up" data-aos-delay="200">
                                            <label for="message">MESSAGE</label>
                                            <textarea name="message" id="message" rows="9" placeholder="Votre message"
                                                v-model="contactForm.message" class="form-control"></textarea>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center" data-aos="fade-up" data-aos-delay="300">
                                        <argon-button color="warning" size="sm"
                                            :disabled="isSubmitting">Envoyer</argon-button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    </main>
    <app-footer />
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importer le CSS pour AOS
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { useToast } from "vue-toastification";
import ArgonButton from "@/components/ArgonButton.vue";
import DashboardAdminMoneyLineChartSafe from "../examples/Charts/DashboardAdminMoneyLineChartSafe.vue";
import DashboardAdminMoneyLineChartAggressive from "../examples/Charts/DashboardAdminMoneyLineChartAggressive.vue";
import apiClient from "@/apiService.js";
import Chart from "chart.js/auto";

const body = document.getElementsByTagName("body")[0];

export default {
    name: "Home",
    components: {
        Navbar,
        AppFooter,
        ArgonButton,
        DashboardAdminMoneyLineChartSafe,
        DashboardAdminMoneyLineChartAggressive,


    },
    setup() {
        const toast = useToast();

        return { toast };
    },
    created() {
        this.$store.state.hideConfigButton = true;
        this.$store.state.showNavbar = true;
        this.$store.state.showSidenav = false;
        this.$store.state.showFooter = false;
        body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.$store.state.hideConfigButton = false;
        this.$store.state.showNavbar = true;
        this.$store.state.showSidenav = true;
        this.$store.state.showFooter = true;
        body.classList.add("bg-gray-100");
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (this.chart) {
            this.chart.destroy();
        }
    },
    async mounted() {
        await this.getAdminDashboard();
        AOS.init({
            duration: 2000, // Durée de l'animation
            // autres options...
        });
        // Logique pour le loader
        const loader = document.querySelector(".edica-loader");
        if (loader) {
            loader.style.display = 'none';
        }
        this.startAutoScroll();

        this.createChart();

    },
    data() {
        return {
            stats: {
                TotalMoneyDailyGraphSafe: [],
                TotalMoneyDailyGraphAggressive: [],
            },
            activeIndex: 0,
            faqs: [
                {
                    id: "edicaAboutFaqContent1",
                    question: "Comment s'inscrire ?",
                    answer: "Créez un compte sur notre plateforme et suivez les différrents tutoriels d'inscriptions, n'hésitez pas àrevenir vers nous en cas de besoin.",
                    open: false, // Etat initial de l'accordéon (fermé)
                },
                {
                    id: "edicaAboutFaqContent2",
                    question: "Quel est le délai pour débuter l'aventure?",
                    answer: "Il n'y a pas de délai. Dès que l'inscription et la configuration sont effectuées, vous suivrez automatiquement toutes les positions prises par la stratégie !",
                    open: false,
                },
                {
                    id: "edicaAboutFaqContent1",
                    question: "Combien de temps faut-il consacrer par jours/semaines ?",
                    answer: " Grâce à notre système entièrement automatisé, aucun engagement quotidien ou hebdomadaire n'est requis. Une fois la configuration effectuée, notre solution prend en charge l'exécution des stratégies. Vous n'avez pas à consacrer de temps régulier, car tout fonctionne de manière automatique, libérant ainsi votre emploi du temps pour d'autres activités. Profitez simplement des résultats sans la nécessité de gérer activement les opérations au quotidien.",
                    open: false, // Etat initial de l'accordéon (fermé)
                },
                {
                    id: "edicaAboutFaqContent1",
                    question: "Est-ce compatible avec un travail classique ?",
                    answer: "Absolument ! Notre solution est conçue pour s'adapter parfaitement à un emploi du temps chargé. Sa nature entièrement automatisée vous permet de concilier facilement notre service avec votre travail classique. Vous n'avez pas besoin de surveiller activement les opérations, ce qui vous offre la flexibilité nécessaire pour vaquer à vos occupations professionnelles tout en bénéficiant des avantages de nos stratégies d'investissement.",
                    open: false, // Etat initial de l'accordéon (fermé)
                },
                {
                    id: "edicaAboutFaqContent1",
                    question: "Comment nos fonds sont-ils gérés et qui a accès à ces derniers ?",
                    answer: "Vos fonds sont sécurisés sur la plateforme d'échange Bitget, garantissant ainsi une protection maximale. Nous n'avons pas accès à ces fonds, seuls nos clients en ont le contrôle exclusif. Vous pouvez déposer ou retirer vos fonds à tout moment, offrant une flexibilité totale dans la gestion de votre investissement. Cette sécurité et cette accessibilité sont au cœur de notre engagement envers nos clients.",
                    open: false, // Etat initial de l'accordéon (fermé)
                },
            ],
            testimonials: [
                {
                    text: "Des résultats incroyables ! J'ai été bluffé...",
                    avatar: require("../assets/img/home/oval.png"),
                    name: "J. D.",
                    position: "Ingénieur"
                },
                {
                    text: "Un service client exceptionnel ! J'ai eu besoin d'aide pour la configuration et l'équipe a été d'une efficacité remarquable. Toujours là pour répondre aux questions avec gentillesse et professionnalisme.",
                    avatar: require("../assets/img/home/oval-copy-3.png"),
                    name: "M. T.",
                    position: "Graphiste"
                },
                {
                    text: "Des résultats au-delà de mes attentes ! Les backtests des devises m'ont permis d'optimiser mes investissements. Je recommande vivement cette plateforme pour sa fiabilité.",
                    avatar: require("../assets/img/home/oval-copy-4.png"),
                    name: "L. B.",
                    position: "Chef de projet"
                },
                {
                    text: "La précision des résultats est remarquable. Grâce à ce service, j'ai pu prendre des décisions éclairées, ce qui a considérablement amélioré mes performances d'investissement. Une expérience vraiment satisfaisante !",
                    avatar: require("../assets/img/home/oval-copy-2.png"),
                    name: "C. F.",
                    position: "Etudiant"
                },
                {
                    text: "La disponibilité 7j/7 est un vrai plus. Avoir un support toujours accessible en cas de besoin est rassurant. Cela fait toute la différence, merci pour cette qualité de service !",
                    avatar: require("../assets/img/home/oval-copy.png"),
                    name: "R. P.",
                    position: "Infirmier"
                },
            ],
            contactForm: {
                name: '',
                phone: '',
                email: '',
                subject: '',
                message: ''
            },
            chart: null,
            strategy: 'safe',
            amount: 500,
            start_date: '2024-03-01',
            minStartDate: '2024-03-01',
            maxStartDate: new Date().toISOString().split('T')[0],
            amountError: '',
            startDateError: '',
            totalGains: 0,
            totalGainsPercent: 0,
            capitalActual: 0,

        };
    },
    methods: {
        showDropdown() {
            this.dropdownVisible = true;
        },
        hideDropdown() {
            this.dropdownVisible = false;
        },
        toggleFaq(faq) {
            faq.open = !faq.open;
        },
        setActiveIndex(index) {
            if (this.interval) {
                clearInterval(this.interval);
            }
            this.activeIndex = index;
            this.startAutoScroll();
        },
        startAutoScroll() {
            this.interval = setInterval(() => {
                const nextIndex = (this.activeIndex + 1) % this.testimonials.length;
                this.setActiveIndex(nextIndex);
            }, 10000); // Change toutes les 10 secondes
        },
        validateForm() {
            let errors = '';
            let isValid = true;

            // Exemple de validation
            if (!this.contactForm.name) {
                errors = 'Le nom est requis.';
                isValid = false;
            }

            if (!this.contactForm.email) {
                errors = 'L\'email est requis.';
                isValid = false;
            }

            if (!this.contactForm.subject) {
                errors = 'Le sujet est requis.';
                isValid = false;
            }

            if (!this.contactForm.message) {
                errors = 'Le message est requis.';
                isValid = false;
            }

            // toasts d'erreur
            if (!isValid) {
                this.toast.error(errors);
                return false;
            }

            return true;
        },
        async submitForm() {
            if (this.validateForm() == true) {
                // console.log(this.contactForm)
                this.toast.success("Votre message a été envoyé avec succès !");
                // try {
                //     const response = await this.$axios.post('/contact', this.contactForm);
                //     this.toast.success(response.data.message);
                // } catch (error) {
                //     this.toast.error(error.response.data.message);
                // }
            }
        },
        async getAdminDashboard() {
            try {
                const response = await apiClient.get("/users/getGraphs");
                if (response.status === 200) {

                    this.stats.TotalMoneyDailyGraphSafe = response.data.TotalMoneyDailyGraphSafe;
                    this.stats.TotalMoneyDailyGraphAggressive = response.data.TotalMoneyDailyGraphAggressive;

                }
            } catch (error) {
                if (error.response) {
                    this.toast.error(error.response.data.error);
                }
            }
        },
        async createChart() {
            this.$nextTick(() => {
                // Vérifiez si un graphique existe déjà et détruisez-le
                if (this.chart) {
                    this.chart.destroy();
                }

                // Sélectionner l'ensemble de données basé sur la stratégie
                if (this.strategy === 'safe') {
                    this.arrayValue = this.stats.TotalMoneyDailyGraphSafe;
                } else {
                    this.arrayValue = this.stats.TotalMoneyDailyGraphAggressive;
                }

                // Filtrer les données basées sur start_date
                const startDate = new Date(this.start_date);
                const filteredData = this.arrayValue.filter(item => new Date(item.date) >= startDate);

                if (filteredData.length === 0) {
                    return;
                }

                const labels = filteredData.map(item => new Date(item.date).toLocaleDateString()); // Formattez la date

                // Initialisez le capital avec this.amount
                let capital = this.amount;
                const data = filteredData.map(item => {
                    capital += capital * (item.profit_percent / 100);
                    return parseFloat(capital.toFixed(2)); // Pour éviter les problèmes de précision flottante
                });

                const canvas = document.getElementById('chart-line-capital-simulation');
                if (!canvas) {
                    console.error('Canvas not found');
                    return;
                }

                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    console.error('2D context not found');
                    return;
                }

                const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                gradient.addColorStop(0, 'rgba(0, 123, 255, 0.5)');
                gradient.addColorStop(1, 'rgba(0, 123, 255, 0)');

                this.totalGains = (data[data.length - 1] - this.amount).toFixed(2);
                this.totalGainsPercent = ((this.totalGains / this.amount) * 100).toFixed(2);
                this.capitalActual = data[data.length - 1];

                this.chart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: 'Capital',
                            data: data,
                            backgroundColor: gradient,
                            borderColor: 'rgba(0, 123, 255, 1)',
                            borderWidth: 2,
                            pointRadius: 3,
                            pointBackgroundColor: 'rgba(0, 123, 255, 1)',
                            pointBorderColor: '#fff',
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(255, 0, 0, 1)',
                            pointHoverBorderColor: 'rgba(220, 220, 220, 1)',
                            pointHoverBorderWidth: 2,
                            tension: 0.4 // Lissage de la ligne
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                grid: {
                                    display: false,
                                },
                                ticks: {
                                    display: true,
                                    font: {
                                        size: 12,
                                        family: 'Arial, sans-serif'
                                    },
                                    color: '#000'
                                }
                            },
                            y: {
                                grid: {
                                    display: true,
                                    color: 'rgba(200, 200, 200, 0.2)'
                                },
                                ticks: {
                                    display: true,
                                    font: {
                                        size: 12,
                                        family: 'Arial, sans-serif'
                                    },
                                    color: '#000'
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: true,
                                labels: {
                                    font: {
                                        size: 14,
                                        family: 'Arial, sans-serif'
                                    },
                                    color: '#000'
                                }
                            },
                            tooltip: {
                                enabled: true,
                                mode: 'index',
                                intersect: false,
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                titleFont: {
                                    size: 14,
                                    family: 'Arial, sans-serif'
                                },
                                bodyFont: {
                                    size: 12,
                                    family: 'Arial, sans-serif'
                                },
                                footerFont: {
                                    size: 10,
                                    family: 'Arial, sans-serif'
                                },
                                callbacks: {
                                    label: function (tooltipItem) {
                                        return `Capital: ${tooltipItem.formattedValue}`;
                                    }
                                }
                            }
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: true
                        },
                        layout: {
                            padding: {
                                left: 10,
                                right: 10,
                                top: 10,
                                bottom: 10
                            }
                        },
                        elements: {
                            line: {
                                borderJoinStyle: 'round',
                                borderCapStyle: 'round'
                            }
                        }
                    }
                });
            });
        },
        validateAmount() {
            if (this.amount < 300 || isNaN(this.amount)) {
                this.amountError = `Le montant doit être supérieur ou égal à 300 €.`;
                if (this.amount < 300) {
                    this.amount = 300;
                }
            } else {
                this.amountError = '';
            }
            this.createChart();
        },
        validateStartDate(createChart) {
            const startDate = new Date(this.start_date);
            const minDate = new Date(this.minStartDate);
            const maxDate = new Date(this.maxStartDate);

            if (isNaN(startDate.getTime()) || startDate < minDate || startDate > maxDate) {
                // Afficher un message d'erreur si la date est invalide ou hors des limites
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                this.startDateError = `La date de début doit être comprise entre ${minDate.toLocaleDateString(undefined, options)} et ${maxDate.toLocaleDateString(undefined, options)}.`;

                // Corriger la date si elle est invalide
                if (isNaN(startDate.getTime()) || startDate < minDate) {
                    this.start_date = this.minStartDate;
                } else if (startDate > maxDate) {
                    this.start_date = this.maxStartDate;
                }

                // Ne pas redessiner le graphique si la date est invalide
                return;
            } else {
                this.startDateError = '';
            }

            // Redessiner le graphique uniquement si les dates sont valides et l'appel vient de 'blur' event
            if (createChart) {
                this.createChart();
            }
        }

    },
    watch: {
        strategy() {
            this.createChart();
        },
    }
};

</script>

<style>
@import '../assets/css/style.css';


@media (max-width: 757px) {
    .edica-landing-section-title {
        font-size: 1em !important;
    }
}
</style>