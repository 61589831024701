<template>
  <div id="app">
    <!-- <Configurator /> -->
    <!-- <div v-if="apiError" class="d-flex justify-content-center align-items-center vh-100">
      <div class="text-center p-5 bg-white rounded shadow">
        <h1>Maintenance en cours</h1>
        <p>Le site est actuellement en maintenance.</p>
        <p>Veuillez réessayer plus tard.</p>
      </div>
    </div> -->
    <div>
      <div v-show="this.$store.state.layout === 'landing'" class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>
      <sidenav
        :custom_class="this.$store.state.mcolor"
        :class="[
          this.$store.state.isTransparent,
          this.$store.state.isRTL ? 'fixed-end' : 'fixed-start'
        ]"
        v-if="this.$store.state.showSidenav"
      />
      <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <!-- nav -->
        <navbar
          :class="[navClasses]"
          :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : 'text-white'"
          :minNav="navbarMinimize"
          v-if="this.$store.state.showNavbar"
        />
        <router-view />
        <app-footer v-show="this.$store.state.showFooter" />
        <configurator
          :toggle="toggleConfigurator"
          :class="[
            this.$store.state.showConfig ? 'show' : '',
            this.$store.state.hideConfigButton ? 'd-none' : ''
          ]"
        />
      </main>
    </div>
  </div>
</template>

<script>
import Sidenav from "./examples/Sidenav";
// import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
import Configurator from './components/Configurator.vue';

export default {
  name: "App",
  components: {
    Sidenav,
    // Configurator,
    Navbar,
    AppFooter,
    Configurator
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"])
  },
  computed: {
    navClasses() {
      return {
        "position-sticky bg-white left-auto top-2 z-index-sticky":
          this.$store.state.isNavFixed && !this.$store.state.darkMode,
        "position-sticky bg-default left-auto top-2 z-index-sticky":
          this.$store.state.isNavFixed && this.$store.state.darkMode,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state.isAbsolute,
        "px-0 mx-4": !this.$store.state.isAbsolute
      };
    },
    ...mapGetters(["apiError"])
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  }
};
</script>

<style scoped>
.maintenance-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
