<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8">
        <div class="row mt-4">
          <div class="row">
            <div class="col-md-4">
              <default-info-card
                :classIcon="stats.total_earned.classIcon"
                :title="stats.total_earned.title"
                :desc="stats.total_earned.desc"
                :value="'$' + stats.total_earned.value"
              />
            </div>
            <div class="col-md-4">
              <default-info-card
                :classIcon="stats.total_earned_month.classIcon"
                :title="stats.total_earned_month.title"
                :desc="stats.total_earned_month.desc"
                :value="'$' + stats.total_earned_month.value"
              />
            </div>
            <div class="col-md-4">
              <default-info-card
                :classIcon="stats.total_earned_week.classIcon"
                :title="stats.total_earned_week.title"
                :desc="stats.total_earned_week.desc"
                :value="'$' + stats.total_earned_week.value"
              />
            </div>
          </div>
          <div class="row my-4">
            <div class="col-md-6">
              <default-info-card
                :classIcon="stats.profit_sharing_pourcentage.classIcon"
                :title="stats.profit_sharing_pourcentage.title"
                :desc="stats.profit_sharing_pourcentage.desc"
                :value="stats.profit_sharing_pourcentage.value + '%'"
              />
            </div>
            <div class="col-md-6">
              <default-info-card
                :classIcon="stats.profit_sharing_amount.classIcon"
                :title="stats.profit_sharing_amount.title"
                :desc="stats.profit_sharing_amount.desc"
                :value="'$' + stats.profit_sharing_amount.value"
              />
            </div>
          </div>
          <div class="row my-4">
            <div class="col-md-6">
              <default-info-card
                :classIcon="stats.invoce_date.classIcon"
                :title="stats.invoce_date.title"
                :desc="stats.invoce_date.desc"
                :value="stats.invoce_date.value"
              />
            </div>
            <div class="col-md-6">
              <default-info-card
                :classIcon="stats.invoce_deadline.classIcon"
                :title="stats.invoce_deadline.title"
                :desc="stats.invoce_deadline.desc"
                :value="stats.invoce_deadline.value"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <invoice-card class="mt-4" :invoices="invoices"/>

        <div>
          <argon-button color="success" size="md" type="submit" class="my-2" @click.prevent="createTicket">Confirmer le paiement</argon-button>
        </div>

        <div>
          <argon-button color="success" size="md" type="submit" class="my-2" @click.prevent="createTicket">Historique des positions</argon-button>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
import InvoiceCard from "./components/InvoiceUser.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import apiClient from "@/apiService.js";



export default {
  name: "ProfitSharing",
  components: {
    DefaultInfoCard,
    InvoiceCard,
    ArgonButton,
  },
  data() {
    return {
      invoices: [],
      stats:{
        total_earned: {
          classIcon: "text-white fab fa-paypal",
          title: "Profits",
          desc: "Profit total",
          value: "",
        },
        total_earned_month: {
          classIcon: "text-white fab fa-paypal",
          title: "Total Mois",
          desc: "Profit sur le mois en cours",
          value: "",
        },
        total_earned_week: {
          classIcon: "text-white fab fa-paypal",
          title: "Total Semaine",
          desc: "Profit sur la semaine en cours",
          value: "",
        },
        profit_sharing_pourcentage: {
          classIcon: "text-white fab fa-paypal",
          title: "Taux de profit",
          desc: "Le pourcentage des profits dû",
          value: "",
        },
        profit_sharing_amount: {
          classIcon: "text-white fab fa-paypal",
          title: "Profit Sharing",
          desc: "Montant des profits dû",
          value: "",
        },
        invoce_date: {
          classIcon: "text-white fab fa-paypal",
          title: "Date facture",
          desc: "Date génération facture",
          value: "",
        },
        invoce_deadline: {
          classIcon: "text-white fab fa-paypal",
          title: "Date limite",
          desc: "Deadline paiement facture",
          value: "",
        },
      },
    };
  },
  methods: {
    async getUserFactures() {
      try {
        const response = await apiClient.get("/facture/getFacturesByUserId");
        if (response.status === 200) {
          this.invoices = response.data;
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    async getUserStats() {
      try {
        const response = await apiClient.get("/facture/getStatsByUserId");
        if (response.status === 200) {
          this.updateStats(response.data);
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    updateStats(data) {
    // Exemple de structure de données attendue : { total_earned: "$9500.00", ..., invoce_deadline: "27/03/24" }
    Object.keys(this.stats).forEach(key => {
      if (data[key] && data[key].value) {
        this.stats[key].value = data[key].value;
      }
    });
  },
  },
  mounted() {
    this.getUserFactures();
    this.getUserStats();
  },
};
</script>
