<template>
  <div class="card">
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>Mon réseau</h6>

      <div class="d-flex align-items-center">
        <p class="mb-0 mr-3 referral-code-text">Votre code de parrainage : <span class="referral-code">{{ referralCode }}</span></p>
        <button class="btn btn-primary btn-sm copy-button" @click="copyReferralCode">
          <i class="fas fa-copy mr-2"></i> Copier
        </button>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0" v-if="networkData.length > 0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Pseudo</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Lien</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date
                d'inscription</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in paginatedData" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <img src="../../assets/img/Avatar.webp" class="avatar avatar-sm me-3" alt="user avatar" />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ user.pseudo }}</h6>
                  </div>
                </div>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ user.my_id === user.sponsor_id ? 'Direct' : user.my_id === user.sponsor_level_2_id ? 'Niveau 2' :
          user.my_id === user.sponsor_level_3_id ? 'Niveau 3' : user.my_id === user.sponsor_level_4_id ? 'Niveau 4' : 'Niveau 5' }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="text-secondary text-xs font-weight-bold">{{ formatDate(user.registration_date) }}</span>
              </td>
              <td class="align-middle text-center text-sm">
  
                <span v-if="user.admin_validated === true && user.valid_connection === true"
                        class="badge badge-sm bg-gradient-success">Actif</span>
                <span v-else-if="user.admin_validated !== true && user.valid_connection === true"
                        class="badge badge-sm bg-gradient-warning">En attente de validation</span>
                <span v-else
                        class="badge badge-sm bg-gradient-secondary">Inactif</span>


              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="card">
          <div class="card-body text-center">
            <span class="text-secondary">Il n'y a pas de membre dans votre réseau.</span>
          </div>
        </div>
        <div class="pagination-buttons mt-3">
          <ArgonButton @click="prevPage" :disabled="currentPage <= 1" class="btn btn-primary" style="background-color: #5e72e4">Précédent</ArgonButton>
          <ArgonButton @click="nextPage" :disabled="currentPage >= totalPages" class="btn btn-primary" style="background-color: #5e72e4">Suivant</ArgonButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "network-table",
  components: {
    ArgonButton,
  },
  data() {
    return {
      networkData: [],
      referralCode: "",
      currentPage: 1,
      itemsPerPage: 10,
    }
  },
   setup() {
      const toast = useToast();
  
      return { toast };
    },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.networkData.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.networkData.length / this.itemsPerPage);
    }
  },
  mounted() {
    this.fetchNetworkData();
    this.getMyReferralCode();
  },
  methods: {
    async fetchNetworkData() {
      try {
        const response = await apiClient.get("/network/myNetwork");
        if (response.status === 200) {
          this.networkData = response.data;
          console.log(this.networkData)
        }
      } catch (error) {
        // console.error(error);
        this.toast.error("Erreur lors de la récupération des données du réseau");
      }
    },
    async getMyReferralCode() {
      try {
        const response = await apiClient.get("/network/myReferralCode");
        if (response.status === 200) {
          this.referralCode = response.data;
        }
      } catch (error) {
        // console.error(error);
        this.toast.error("Erreur lors de la récupération du code de parrainage");
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(dateString).toLocaleDateString('fr-FR', options);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    copyReferralCode() {
      var referralLink = "https://nexifyr.fr/signup?ref=" + this.referralCode;
      navigator.clipboard.writeText(referralLink).then(() => {
        this.toast.success('Code de parrainage copié');
      }, (err) => {
        console.error('Erreur lors de la copie du code de parrainage :', err);
      });
    },
  },
};
</script>

<style scoped>
/* Styles existants */
.pagination-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.referral-code-text {
  margin-bottom: 0;
  font-weight: bold;
  color: #333;
}

.referral-code {
  color: #007bff;
}

.copy-button {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.copy-button i {
  font-size: 1rem;
}

/* Media Queries pour les appareils mobiles */
@media (max-width: 768px) {
  .referral-code-text, .copy-button {
    font-size: 0.65rem; /* Réduction de la taille du texte */
  }

  .copy-button i {
    font-size: 0.9rem; /* Réduction de la taille de l'icône */
  }

  /* Vous pouvez ajouter d'autres styles spécifiques pour mobile ici si nécessaire */
}
</style>

