<template>
  <div class="chat-app-container">
    <div class="chat-app-col">
      <div class="chat-header">
        <a class="btn btn-outline-secondary" @click="goBack">
          <i class="fas fa-arrow-left"></i> Retour
        </a>
        <!-- <a class="btn btn-outline-danger" @click="closeTicket">
          <i class="fas fa-times-circle"></i> Fermer le ticket
        </a> -->
      </div>
      <div class="chat-body" ref="chatBody">
        <ul class="chat-messages">
          <li v-for="(message, index) in messages" :key="index" :class="messageClass(message)">
          <img v-if="message.entry_type === 'Support'" class="avatar service-avatar" src="../../src/assets/img/AvatarNew.webp" />
          <div class="message-content">
            <div class="message-text">{{ message.message }}</div>
            <div class="message-timestamp">{{ message.date }}</div>
          </div>
          <img v-if="message.entry_type === 'User'" class="avatar user-avatar" src="../../src/assets/img/Avatar.webp" />
        </li>
        </ul>
      </div>
      <div v-if="ticketStatus !== 'Closed'" class="chat-footer">
      <textarea class="message-input" v-model="newMessage" placeholder="Votre message..."></textarea>
      <button class="send-message-button" @click="sendMessage">
        <i class="fas fa-paper-plane"></i>
      </button>
    </div>
    <div v-else class="chat-footer-closed">
      <p class="text-center">Le ticket est fermé. Vous ne pouvez plus envoyer de messages.</p>
    </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
//import ArgonButton from "@/components/ArgonButton.vue";
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
// import router from "@/router/index.js";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "supportDetail",
  data() {
    return {
      ticketId: null, 
      messages: [], 
      newMessage: '',
      ticketStatus: "Closed"
    };
  },
  created() {
    // Récupération de l'ID du ticket à partir de l'URL lorsque le composant est créé
    this.ticketId = this.$route.query.ticketId;
    if (!this.ticketId) {
      this.toast.error("Le ticket n'a pas été trouvé.");
      this.$router.push({ name: 'Support' });
    } else {
      this.fetchMessages();
    }
  },
  //components: {  ArgonButton },
  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    //Récupérer les messages
    async fetchMessages() {
      try {
        const response = await apiClient.get(`/support/getTicketById/${this.ticketId}`);
        if (response.status === 200) {
          this.messages = response.data.replies;
          this.ticketStatus = response.data.status;
        } else {
          this.toast.error("Le ticket n'a pas été trouvé.");
          this.$router.push({ name: 'Support' });
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
        this.$router.push({ name: 'Support' });
      }
    },
    // Function to send a new message
    async sendMessage() {

      // Vérifier si les deux derniers messages ne sont pas de l'utilisateur, sinon il doit attendre une réponse
      if (this.messages.length > 1) {
        if (this.messages[this.messages.length - 1].entry_type === 'User' && this.messages[this.messages.length - 2].entry_type === 'User' && !this.$store.state.isAdmin) {
          this.toast.error("Vous devez attendre une réponse avant d'envoyer un autre message.");
          return;
        }
      }

      if (this.ticketStatus === "Closed") {
        this.toast.error("Le ticket est fermé. Vous ne pouvez pas envoyer de message.");
        return;
      }

      if (this.newMessage) {
        try {
          const response = await apiClient.post(`/support/replyToTicket`, {
            message: this.newMessage,
            ticket_id: this.ticketId
          });
          if (response.status === 200) {
            
            this.newMessage = '';
            this.fetchMessages();
            this.scrollToBottom();
          } else {
            this.toast.error("Le message n'a pas pu être envoyé.");
          }
        } catch (error) {
          this.toast.error(error.response.data.error);
        }
      }
    },


    // Function to go back
    goBack() {
      this.$router.push({ name: 'Support' });
    },
    // Function to close the ticket


    messageClass(message) {
      return {
        'chat-message': true,
        'chat-message-user': message.entry_type === 'User',
        'chat-message-service': message.entry_type === 'Support'
      };
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatBody = this.$refs.chatBody;
        chatBody.scrollTop = chatBody.scrollHeight;
      });
    },
  },
  mounted() {
    this.$store.state.isAbsolute = false;
    setNavPills();
    setTooltip();
    this.scrollToBottom();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>

<style scoped>
/* Assuming this scoped style applies to the component where your template is */
.chat-app-container {
  font-family: 'Helvetica Neue', sans-serif;
  margin: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-header {
  padding: 10px;
  background: #00bfa5; /* Adjust the color to match your design */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white; /* Adjust text color for better visibility on the green background */
}

.btn-outline-secondary, .btn-outline-danger {
  margin: 0 5px;
  border-color: white; /* Ensure buttons are visible on the green background */
  color: white;
}

.btn-outline-secondary:hover, .btn-outline-danger:hover {
  background-color: white; /* Change hover state as needed */
  color: #00bfa5;
}

/*.chat-body {
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
}*/

.chat-body {
  max-height: 500px; /* Ajustez cette valeur selon vos besoins */
  overflow-y: auto; /* Ajoute une barre de défilement si le contenu dépasse max-height */
  padding: 20px;
}


.chat-messages {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chat-message {
  display: flex;
  margin-bottom: 10px;
}

.chat-message-user {
  justify-content: flex-end;
}

.chat-message-service {
  justify-content: flex-start;
}

.message-content {
  /*background: #e0ffe0; /* Light green background for user */
  background: red; /* Light green background for user */
  color: #333;
  border-radius: 18px;
  padding: 12px 20px;
  position: relative;
  max-width: 60%;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}

/* Inverting the colors for the service */
.message-content.service {
  background: #f0f0f0; /* Different background color for service */
}

/*.message-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
}*/

/* User message styling */
.chat-message-user .message-content {
  background: #ffffff; /* White for user */
  color: #333;
}

.chat-message-user .message-content::after {
  right: -10px;
  border: 15px solid transparent;
  border-left-color: #ffffff; /* Tail matches the white bubble */
  border-right: 0;
  border-bottom: 0;
  top: 0;
}

/* Service message styling */
.chat-message-service .message-content {
  background: #e0ffe0; /* Light green for service */
  color: #333;
}

.chat-message-service .message-content::after {
  left: -10px;
  border: 15px solid transparent;
  border-right-color: #e0ffe0; /* Tail matches the light green bubble */
  border-left: 0;
  border-bottom: 0;
  top: 0;
}

/* Additional styles... (rest of your styles) */


.message-timestamp {
  font-size: 0.75rem;
  color: #666;
  margin-top: 5px;
  text-align: right;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-self: flex-end;
}

.chat-footer {
  padding: 10px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  border-top: 1px solid #e0e0e0;
}

.message-input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 18px;
  outline: none;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
}

.send-message-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 18px;
  cursor: pointer;
}

.send-message-button i {
  margin-right: 5px;
}

</style>