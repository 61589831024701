<template>
  <div class="row text-center justify-content-center">
    <!-- Card 1: Abonnement Basique -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Abonnement Basique</h4>
        </div>
        <div class="card-body">
          <p>Découvrez nos services.</p>
          <p class="fw-bold">1 mois - 20€</p>
          <div class="text-center d-flex justify-content-center">
            <ul>
              <li>Trade sur 2 paires</li>
              <li>Support 24/7</li>
              <li>Prime de parrainage</li>
            </ul>
          </div>
          <argon-button color="default" @click="selectPlan('basique')">Choisir</argon-button>
        </div>
      </div>
    </div>

    <!-- Card 2: Abonnement Pro -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Abonnement Pro</h4>
        </div>
        <div class="card-body">
          <p>Augmenter son capital.</p>
          <p class="fw-bold">3 mois - 50€</p>
          <div class="text-center d-flex justify-content-center">
            <ul>
              <li>Trades sur 5 paires</li>
              <li>Support 24/7</li>
              <li>Prime de parrainage</li>
            </ul>
          </div>
          <argon-button color="default" @click="selectPlan('pro')">Choisir</argon-button>
        </div>
      </div>
    </div>

    <!-- Card 3: Abonnement Premium -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Abonnement Premium</h4>
        </div>
        <div class="card-body">
          <p>Une vision long terme.</p>
          <p class="fw-bold">6 mois - 100€</p>
          <div class="text-center d-flex justify-content-center">
            <ul>
              <li>Trades sur 10 paires</li>
              <li>Support dédié</li>
              <li>Parrainages sur 3 niveaux</li>
            </ul>
          </div>
          <argon-button color="default" @click="selectPlan('premium')">Choisir</argon-button>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5 justify-content-center">
    <div class="col-lg-4 mb-lg-0">
      <div class="card h-100 p-3">
        <div class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
          :style="{ backgroundImage: 'url(' + require('@/assets/img/ivancik.jpg') + ')' }">
          <span class="mask bg-gradient-dark"></span>
          <div class="card-body position-relative z-index-1 p-3 h-100">
            <div class="d-flex flex-column h-100">
              <h5 class="text-white font-weight-bolder mb-4 pt-2">
                Votre abonnement -

                <!-- sI C'est Active alors affiche en vert -->
                <span v-if="userSubscription && userSubscription.status === 'Active'" class="text-success">
                  Actif
                </span>

                <span v-else-if="userSubscription && (!userSubscription.status || userSubscription.status === 'Inactive')"
                  class="text-danger">
                  Inactif
                </span>
                <span v-else class="text-warning">
                  En attente
                </span>
              </h5>

              <p class="text-white" v-if="userSubscription && userSubscription.status === 'Pending'">
                Votre abonnement est en attente de validation.
              </p>
              <!-- Si l'abonnement est actif ; -->
              <p class="text-white"
                v-if="userSubscription && userSubscription.status === 'Active' || userSubscription.status === 'Inactive'">
                Date de fin : {{ userSubscription.subscription_expiration }}
              </p>
              <p class="text-white">
                Vous pouvez prolonger ou renouveller votre abonnement à tout moment.
              </p>
              <p class="text-white">
                Argent parrainages disponible : 100€
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-7 mb-lg-0" v-if="selectedPlan">
      <div class="card">
        <div class="card-body p-3">
          <div class="row">
            <div class="col-lg-6 mb-lg-0 mb-4">
              <div class="d-flex flex-column h-100">
                <p class="mb-1 pt-2 text-bold">Paiements</p>
                <p class="mb-5">Veuillez choisir votre moyen de paiement.</p>
                
                <div class="d-flex justify-content-center">

                  <div class="payment-method">
                    <input type="radio" id="payment1" value="USDT" v-model="selectedPaymentMethod" name="paymentMethod">
                    <label for="payment1" class="mt-2">USDT - TRC20</label>
                  </div>

                  <div class="payment-method">
                    <input type="radio" id="payment2" value="USDT" v-model="selectedPaymentMethod" name="paymentMethod">
                    <label for="payment2" class="mt-2">USDT - ERC20</label>
                  </div>
                </div>


                <p class="text-center mt-3">Merci de nous envoyer le montant correspondant à l'adresse suivante :</p>
                <p>Montant : {{ montant }}€</p>
                <p>Adresse :</p>

                <argon-button class="mt-3" color="default" @click="createSubscription">Valider</argon-button>
              </div>
            </div>
            <div class="col-lg-4 me-auto ms-0 text-center">
              <div class="bg-gradient-primary border-radius-lg min-height-200">
                <img src="../assets/img/shapes/waves-white.svg" class="position-absolute h-100 top-0 d-md-block d-none"
                  alt="waves" />
                <div class="position-relative pt-5 pb-4">
                  <img class="max-width-500 w-100 position-relative z-index-2"
                    src="../assets/img/illustrations/rocket-white.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import ArgonButton from "@/components/ArgonButton.vue";
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";

export default {
  name: "Subscription",

  components: {
    ArgonButton,
  },
  data() {
    return {
      plans: ["basique", "pro", "premium"],
      selectedPlan: null,
      showPaymentForm: false,
      userSubscription: {
        status: "",
        subscription_date: "",
        subscription_expiration: "",
        subscription_name: "",
      },
      selectedPaymentMethod: null,
      montant: "",
    };
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    selectPlan(plan) {
      // console.log("Abonnement sélectionné:", plan);
      this.selectedPlan = plan;
      this.showPaymentForm = true; // Afficher le formulaire de paiement
      if (plan === "basique") {
        this.montant = 20;
      } else if (plan === "pro") {
        this.montant = 50;
      } else if (plan === "premium") {
        this.montant = 100;
      }
    },
    async createSubscription() {
      try {
        const response = await apiClient.post("/api/subscriptions", {
          plan: this.selectedPlan,
        });

        this.toast.success(response.data.message);
  
      } catch (error) {
        this.toast.error("Une erreur est survenue !");
      }
    },
    async getUserSubscription() {
      try {
        const response = await apiClient.get("/subscription/getUserSubscription");
        this.userSubscription = response.data;
      } catch (error) {
        this.toast.error("Une erreur est survenue !");
      }
    },
  },
  mounted() {
    this.getUserSubscription();
  },
};

</script>

<style scoped>
.card-header {
  background-color: #bd9512;
  border-bottom: 1px solid #e9ecef;
  border-radius: 0.375rem 0.375rem 0 0;
  padding-bottom: 0;
}

.card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  color: #fff;
}

.payment-method {
  display: flex;
  align-items: center;
  margin-right: 55px; /* Ajustez selon les besoins */
  /* On agrandis les input et le texte */
  transform: scale(1.3);
}


</style>
