<template>
    <div class="card">
        <div class="pb-0 card-header mb-0">
            <h6>{{ title }}</h6>
            <p class="text-sm">
                <!-- Groupe pour une valeur positive -->
                Total :
                <template v-if="totalGains > 0">
                    <i class="fa fa-arrow-up text-success mr-1"></i>
                    <span class="font-weight-bold">{{ totalGainsPercentage }}%
                        <span v-if="adminPage">| {{ totalGains }} $</span>
                    </span>
                </template>

                <!-- Si c'est égale a 0 -->
                <template v-else-if="totalGains == 0">
                    <i class="fa fa-arrow-right text-secondary mr-1"></i>
                    <span class="font-weight-bold">{{ totalGainsPercentage }}%
                        <span v-if="adminPage">| {{ totalGains }} $</span>
                    </span>
                </template>

                <!-- Groupe pour une valeur non-positive -->
                <template v-else>
                    <i class="fa fa-arrow-down text-danger mr-1"></i>
                    <span class="font-weight-bold">{{ totalGainsPercentage }}%
                        <span v-if="adminPage">| {{ totalGains }} $</span>
                    </span>
                </template>
                <br><br>
                Mois en cours :
                <template v-if="currentMonth > 0">
                    <i class="fa fa-arrow-up text-success mr-1"></i>
                    <span class="font-weight-bold">{{ currentMonthPercentage }}%
                        <span v-if="adminPage">| {{ currentMonth }} $</span>
                    </span>
                </template>

                <!-- Si c'est égal a 0 -->
                <template v-else-if="currentMonth == 0">
                    <i class="fa fa-arrow-right text-secondary mr-1"></i>
                    <span class="font-weight-bold">{{ currentMonthPercentage }}%
                        <span v-if="adminPage">| {{ currentMonth }} $</span>
                    </span>
                </template>

                <template v-else>
                    <i class="fa fa-arrow-down text-danger mr-1"></i>
                    <span class="font-weight-bold">{{ currentMonthPercentage }}%
                        <span v-if="adminPage">| {{ currentMonth }} $</span>
                    </span>
                </template>
                <br><br>
                Semaine en cours :
                <template v-if="currentWeek > 0">
                    <i class="fa fa-arrow-up text-success mr-1"></i>
                    <span class="font-weight-bold">{{ currentWeekPercentage }}%
                        <span v-if="adminPage">| {{ currentWeek }} $</span>
                    </span>
                </template>

                <!-- Si c'est égal a 0 -->
                <template v-else-if="currentWeek == 0">
                    <i class="fa fa-arrow-right text-secondary mr-1"></i>
                    <span class="font-weight-bold">{{ currentWeekPercentage }}%
                        <span v-if="adminPage">| {{ currentWeek }} $</span>
                    </span>
                </template>

                <template v-else>
                    <i class="fa fa-arrow-down text-danger mr-1"></i>
                    <span class="font-weight-bold">{{ currentWeekPercentage }}%
                        <span v-if="adminPage">| {{ currentWeek }} $</span>
                    </span>
                </template>


            </p>
        </div>
        <div class="p-3 card-body">
            <div class="chart">
                <canvas id="chart-line-safe" class="chart-canvas" height="300"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
    name: "gradient-line-chart",

    props: {
        title: {
            type: String,
            default: "Evolutions des gains - Stratégie Safe",
        },
        arrayValue: {
            type: Array,
            default: () => [],
        },
        adminPage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            totalGains: 0,
            totalGainsPercentage: 0,
            currentWeek: 0,
            currentMonth: 0,
            currentWeekPercentage: 0,
            currentMonthPercentage: 0,
            chart: null,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.createChart();
        });
    },
    beforeUnmount() {
        if (this.chart) {
            this.chart.destroy();
        }
    },
    watch: {
        arrayValue() {
            if (this.chart) {
                this.chart.destroy();
            }
            this.createChart();
        },
    },
    methods: {
        createChart() {
            const ctx1 = document.getElementById("chart-line-safe").getContext("2d");
            const gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);

            gradientStroke1.addColorStop(1, "rgba(94, 114, 228, 0.2)");
            gradientStroke1.addColorStop(0.2, "rgba(94, 114, 228, 0.0)");
            gradientStroke1.addColorStop(0, "rgba(94, 114, 228, 0)");

            const labels = this.arrayValue.map(item => new Date(item.date).toLocaleDateString());
            const data = this.arrayValue.map(item => item.balance);

            // Calculate daily percentage changes
            const percentageChanges = data.map((current, index, arr) => {
                if (index === 0) return null; // No change on the first day
                const previous = arr[index - 1];
                return ((current - previous) / previous * 100).toFixed(2); // Percentage change formula
            });

            // Percentage change se trouve dans le tableau avec le profit_percent
            // const percentageChanges = this.arrayValue.map(item => item.profit_percent);

            this.totalGains = (data[data.length - 1] - data[0]).toFixed(2);
            this.totalGainsPercentage = ((this.totalGains / data[0]) * 100).toFixed(2);

            // On calcule les gains sur la semaine en cours (qui commence le lundi) et sur le mois en cours (qui commence le 1er du mois)
            const today = new Date();

            // Fix the start of the current week (Monday)
            let currentWeekStart = new Date(today);
            currentWeekStart.setDate(today.getDate() - today.getDay() + 1); // Adjust to Monday if needed
            if (today.getDay() === 0) { // If today is Sunday, subtract 6 days to get to Monday
                currentWeekStart.setDate(today.getDate() - 6);
            }

            // Fix the start of the current month
            const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);

            // Prepare arrays to hold the data for the current week and month
            const currentWeekData = [];
            const currentMonthData = [];

            // Append data for the current week and month
            Array.prototype.push.apply(currentWeekData, this.arrayValue.filter(item => new Date(item.date) >= currentWeekStart));
            Array.prototype.push.apply(currentMonthData, this.arrayValue.filter(item => new Date(item.date) >= currentMonthStart));

            // If the first element in the filtered list is not the first data point,
            // manually add the last point from the previous period to ensure proper calculation.
            if (currentWeekData.length > 0) {
                const firstWeekDate = new Date(currentWeekData[0].date);
                const lastDataBeforeWeek = this.arrayValue.findLast(item => new Date(item.date) < firstWeekDate);
                if (lastDataBeforeWeek) {
                    currentWeekData.unshift(lastDataBeforeWeek);
                }
            }

            if (currentMonthData.length > 0) {
                const firstMonthDate = new Date(currentMonthData[0].date);
                const lastDataBeforeMonth = this.arrayValue.findLast(item => new Date(item.date) < firstMonthDate);
                if (lastDataBeforeMonth) {
                    currentMonthData.unshift(lastDataBeforeMonth);
                }
            }
            
            const calculateGains = (dataArray) => {
                if (dataArray.length > 1) {
                    let initialBalance = dataArray[0].balance;
                    const finalBalance = dataArray[dataArray.length - 1].balance;
                    const totalGain = finalBalance - initialBalance;
                    const gainPercentage = ((totalGain / initialBalance) * 100);
                    return { gain: totalGain.toFixed(2), gainPercentage: gainPercentage.toFixed(2) };
                } else {
                    return { gain: "0.00", gainPercentage: "0.00" };
                }
            };

            const weekGains = calculateGains(currentWeekData);
            this.currentWeek = weekGains.gain;
            this.currentWeekPercentage = weekGains.gainPercentage;

            const monthGains = calculateGains(currentMonthData);
            this.currentMonth = monthGains.gain;
            this.currentMonthPercentage = monthGains.gainPercentage;

            const adminPage = this.adminPage;

            this.chart = new Chart(ctx1, {
                type: "line",
                data: {
                    labels: labels,
                    datasets: [{
                        label: "Argent",
                        tension: 0.4,
                        borderWidth: 3,
                        pointRadius: 0,
                        borderColor: "#4BB543",
                        backgroundColor: gradientStroke1,
                        fill: true,
                        data: data,
                        maxBarThickness: 6,
                    }],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            intersect: false,
                            mode: "index",
                            callbacks: {
                                label: function (context) {
                                    if (!adminPage) {
                                        const index = context.dataIndex;
                                        const percentageChange = percentageChanges[index];
                                        return percentageChange ? [`Pourcentage: ${percentageChange}%`] : [''];
                                    }
                                    return ''; // Ensures label callback returns an empty string if adminPage is true
                                },
                                afterLabel: function (context) {
                                    if (adminPage) {
                                        const index = context.dataIndex;
                                        const percentageChange = percentageChanges[index];
                                        const moneyInfo = `Argent: ${context.raw} $`;
                                        const percentageInfo = percentageChange ? `Pourcentage: ${percentageChange}%` : '';
                                        return percentageChange ? [moneyInfo, percentageInfo] : [moneyInfo];
                                    }
                                    return ''; // Ensures afterLabel callback returns an empty string if adminPage is false
                                },
                            }
                        },
                    },
                    interaction: {
                        intersect: false,
                        mode: "index",
                    },
                    scales: {
                        y: {
                            grid: {
                                drawBorder: false,
                                display: true,
                                drawOnChartArea: true,
                                drawTicks: false,
                                borderDash: [5, 5],
                            },
                            ticks: {
                                display: true,
                                padding: 10,
                                color: "#fbfbfb",
                                font: {
                                    size: 11,
                                    family: "Open Sans",
                                    style: "normal",
                                    lineHeight: 2,
                                },
                            },
                        },
                        x: {
                            grid: {
                                drawBorder: false,
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false,
                                borderDash: [5, 5],
                            },
                            ticks: {
                                display: true,
                                color: "#ccc",
                                padding: 20,
                                font: {
                                    size: 11,
                                    family: "Open Sans",
                                    style: "normal",
                                    lineHeight: 2,
                                },
                            },
                        },
                    },
                },
            });

        },
    },
};
</script>
