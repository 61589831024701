<template>
  <div class="card pb-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Factures</h6>
        </div>
        <div class="col-6 text-end">
          <argon-button color="success" size="sm" variant="outline">Afficher plus</argon-button>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li
          v-for="invoice in invoices"
          :key="invoice.invoice_id"
          class="list-group-item d-flex justify-content-between ps-0 mb-3 custom-border"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm ps-2">
              {{ new Date(invoice.start_date).toLocaleDateString() }} - {{ new Date(invoice.end_date).toLocaleDateString() }}
            </h6>
            <span v-if="invoice.strategy_type == 1" class="text-xs ps-2">#{{ invoice.invoice_id }} - Stratégie : <b>Safe</b></span>
            <span v-else class="text-xs ps-2">#{{ invoice.invoice_id }} - Stratégie : <b>Aggressive</b></span>
          </div>
          <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center text-sm responsive-details">
            <span>Profit: {{ invoice.profit_client }}</span>
            <span class="ms-md-2">À payer: {{ invoice.profit_sharing_client }}</span>
            <span class="ms-md-2" :class="[invoice.paid ? 'text-success' : 'text-danger', 'fw-bold']">{{ invoice.paid ? 'Payé' : 'Non Payé' }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "invoice-card",
  components: {
    ArgonButton,
  },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.custom-border {
  border: 1px solid #dee2e6;
  border-radius: 0.375rem; /* Adjust this value to your preference */
  background: #fff; /* Ensuring background is white for consistency */
}

.list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.375rem;
  border-top-width: 1px !important; /* Ensure top border is always present */
}

.list-group-item + .list-group-item {
  border-top-width: 1px !important; /* Override to ensure top border is present */
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.border-radius-lg {
  border-radius: 0.375rem;
}

.text-xs {
    white-space: nowrap;
}


@media (max-width: 768px) {
  .responsive-details span {
    display: block;
    margin-bottom: 5px;
  }
}
</style>
