<template>
  <div class="container top-0 z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg'); background-position: top;">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <!-- <h1 class="text-white mb-2 mt-5">Welcome!</h1>
            <p
              class="text-lead text-white" 
            >Use these awesome forms to login or create new account in your project for free.</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="!isVerificationVisible">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Inscription</h5>
            </div>
            <div class="card-body">
              <form role="form">

                <!-- Champ Pseudo -->
                <div class="mb-3">
                  <label for="pseudo">Pseudo <span class="text-danger">*</span></label>
                  <input type="text" id="pseudo" class="form-group form-control" placeholder="Pseudo"
                    aria-label="Pseudo" v-model="pseudo" />
                </div>

                <!-- Champ Email -->
                <div class="mb-3">
                  <label for="email">Email <span class="text-danger">*</span></label>
                  <input type="email" id="email" class="form-group form-control" placeholder="Email" aria-label="Email"
                    v-model="email" />
                </div>

                <!-- Champ Mot de passe avec icône d'œil ajusté -->
                <label for="password">
                  Mot de passe <span class="text-danger">*</span>
                  <i class="fas fa-info-circle ml-1 text-dark cursor-pointer" data-bs-toggle="modal"
                    data-bs-target="#passwordModal"></i>
                </label>
                <div class="mb-3 position-relative">
                  <input :type="showPassword ? 'text' : 'password'" id="password"
                    :class="['form-group form-control', passwordClass]" placeholder="Mot de passe" aria-label="Password"
                    v-model="password" @input="validatePasswordInstant('password')"
                    @blur="validateOnBlur('password')" />
                  <span class="position-absolute top-50 end-0 me-3"
                    style="cursor: pointer; top: 70%; transform: translateY(-50%);" @click="togglePasswordVisibility">
                    <i :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
                  </span>
                </div>

                <!-- Champ Confirmation du mot de passe avec icône d'œil ajusté -->
                <label for="confirmPassword">
                  Confirmez le mot de passe <span class="text-danger">*</span>
                  <i class="fas fa-info-circle ml-1 text-dark cursor-pointer" data-bs-toggle="modal"
                    data-bs-target="#passwordModal"></i>
                </label>
                <div class="mb-3 position-relative">
                  <input :type="showConfirmPassword ? 'text' : 'password'" id="confirmPassword"
                    :class="['form-group form-control', confirmPasswordClass]" placeholder="Confirmez le mot de passe"
                    aria-label="Confirm Password" v-model="confirmPassword"
                    @input="validatePasswordInstant('confirmPassword')" @blur="validateOnBlur('confirmPassword')" />
                  <span class="position-absolute top-50 end-0 me-3"
                    style="cursor: pointer; top: 70%; transform: translateY(-50%);"
                    @click="toggleConfirmPasswordVisibility">
                    <i :class="showConfirmPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
                  </span>
                </div>



                <!-- Champ Code Parrain (Optionnel) -->
                <div class="mb-3">
                  <label for="referral_code">Code Parrain (Optionnel)</label>
                  <input type="text" id="referral_code" class="form-group form-control"
                    placeholder="Code Parrain (Optionnel)" aria-label="Code Parrain" v-model="referral_code"
                    :disabled="isDisabled" />
                </div>

                <!--<div v-if="password && !validatePassword(password)" class="text-danger mb-3">
                  Le mot de passe doit contenir entre 8 et 15 caractères, incluant au moins une majuscule, une minuscule, un chiffre et un caractère spécial.
                </div>-->


                <div class="text-center">
                  <argon-button fullWidth @click.prevent="sendVerificationCode" color="dark" variant="gradient"
                    class="my-4 mb-2" type="submit">Inscription</argon-button>
                </div>
                <p class="text-sm mt-3 mb-0">
                  Déjà inscrit ?
                  <a href="/signin" class="text-dark font-weight-bolder">Connexion</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="isVerificationVisible">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Validation Compte</h5>
            </div>
            <div class="card-body">
              <form role="form">

                <input type="text" class="form-group form-control" placeholder="Code Validation"
                  aria-label="Code Validation" v-model="verification_code" />

                <div class="text-center">
                  <argon-button fullWidth @click.prevent="checkValidationCode" color="dark" variant="gradient"
                    class="my-4 mb-2" type="submit">Inscription</argon-button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- Modale pour les règles de validation du mot de passe -->
  <div class="modal fade" id="passwordModal" tabindex="-1" aria-labelledby="passwordModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="passwordModalLabel">Règles de validation du mot de passe</h5>
          <i class="fas fa-times ml-1 text-dark cursor-pointer" data-bs-dismiss="modal" aria-label="Close"
            style="font-size: 1.3em;"></i>
        </div>
        <div class="modal-body">
          Le mot de passe doit contenir entre 8 et 15 caractères, incluant au moins :
          <ul>
            <li>Une majuscule</li>
            <li>Une minuscule</li>
            <li>Un chiffre</li>
            <li>Un caractère spécial (!@#$%^&amp;*()_+-=[]{};':"\|,.&lt;&gt;/?)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
import router from "@/router/index.js";

export default {
  name: "signin",
  components: {
    Navbar,
    AppFooter,
    ArgonButton,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      pseudo: "",
      email: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      referral_code: "",
      isVerificationVisible: false,
      verification_code: "",
      validedRegistration: false,
      valid_referal_code: "",
      passwordValid: null, // null: non vérifié, true: valide, false: invalide
      confirmPasswordValid: null,
      referral_code_url: this.$route.query.ref,
      isReferalDisabled: false,
    };
  },
  methods: {
    async signup() {
      try {
        const response = await apiClient.post("/auth/register", {
          pseudo: this.pseudo,
          email: this.email,
          password: this.password,
          referral_code: this.valid_referal_code,
        });

        if (response.status !== 200) {
          this.toast.error("Une erreur est survenue !");
        }

      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    async sendVerificationCode() {

      // Réinitialiser les états de validation
      this.passwordValid = null;
      this.confirmPasswordValid = null;

      // Vérifier les critères du mot de passe et s'ils correspondent
      const isPasswordValid = this.validatePassword(this.password);
      const doPasswordsMatch = this.password === this.confirmPassword;

      if (!isPasswordValid || !doPasswordsMatch) {
        // Si le mot de passe n'est pas valide ou s'ils ne correspondent pas, afficher les erreurs
        this.passwordValid = isPasswordValid ? true : false;
        this.confirmPasswordValid = doPasswordsMatch ? true : false;

        // Afficher un message d'erreur global ou spécifique si nécessaire
        if (!isPasswordValid) {
          this.toast.error("Le mot de passe ne respecte pas les critères requis.");
        }
        if (!doPasswordsMatch) {
          this.toast.error("Les mots de passe ne correspondent pas.");
        }
        return; // Arrêter l'exécution si erreur
      }

      try {
        if (this.referral_code === "") {
          this.valid_referal_code = "219c62ab";
        } else {
          this.valid_referal_code = this.referral_code;
        }
        const checkRegister = await apiClient.post("/auth/checkRegister", {
          pseudo: this.pseudo,
          email: this.email,
          password: this.password,
          referral_code: this.valid_referal_code,
        });
        if (checkRegister.status === 200) {
          this.validedRegistration = true;
          // Appelle la fonction signup() pour créer le compte
          await this.signup();
        }
        if (this.validedRegistration) {
          const response = await apiClient.post("/auth/sendVerificationCode", {
            pseudo: this.pseudo,
            email: this.email,
            password: this.password,
            referral_code: this.referral_code,
          });

          if (response.status === 200) {
            this.toast.success("Code de validation envoyé !");
            this.isVerificationVisible = true;
          }
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    async checkValidationCode() {
      try {
        const response = await apiClient.post("/auth/checkVerificationCode", {
          email: this.email,
          verification_code: this.verification_code,
        });

        if (response.status === 200) {
          this.toast.success("Inscription réussie !");
          router.push({ name: "Dashboard" });
        }

      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },


    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    checkPasswordsMatch() {
      if (this.password && this.confirmPassword) {
        return this.password === this.confirmPassword;
      }
      return false;
    },
    validatePassword(password) {
      // Ajout d'une partie à l'expression régulière pour vérifier la présence d'au moins un caractère spécial
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[a-zA-Z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,15}$/;
      return regex.test(password);
    },

    validatePasswordInstant(field) {
      // Valider le mot de passe immédiatement lors de la saisie
      const isValid = this.validatePassword(this[field]);
      this[field + 'Valid'] = isValid ? 'valid' : 'invalid-on-input';
    },

    validateOnBlur(field) {
      // Valider le mot de passe lors du déplacement hors du champ (blur)
      const isValid = this.validatePassword(this[field]);
      this[field + 'Valid'] = isValid ? 'valid' : 'invalid';
    },

  },
  computed: {
    passwordsMatch() {
      if (this.password && this.confirmPassword) {
        return this.password === this.confirmPassword;
      }
      return false;
    },
    passwordClass() {
      return {
        'is-valid-for-password': this.passwordValid === 'valid',
        'is-invalid-for-password': this.passwordValid === 'invalid',
        'is-warning': this.passwordValid === 'invalid-on-input',
      };
    },
    confirmPasswordClass() {
      return {
        'is-valid-for-password': this.confirmPasswordValid === 'valid',
        'is-invalid-for-password': this.confirmPasswordValid === 'invalid',
        'is-warning': this.confirmPasswordValid === 'invalid-on-input',
      };
    },
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
    // Vérifier si `referral_code_url` existe dans l'URL quand le composant est créé
    const referralCodeFromUrl = this.$route.query.ref; // Obtention de la valeur depuis l'URL
    if (referralCodeFromUrl) {
      this.referral_code = referralCodeFromUrl; // Assignation de la valeur au champ `referral_code`
      this.isDisabled = true; // Désactivation du champ si la valeur est présente
    }
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>


<style>
.is-valid-for-password {
  border-color: #28a745 !important;
}

.is-invalid-for-password {
  border-color: #dc3545 !important;
}

.is-warning-for-password {
  border-color: #ffc107 !important;
}

@media (max-width: 600px) {

  input:focus,
  input:hover {
    font-size: 17px;
    /* Empêche le zoom sur les mobiles */
  }
}
</style>