<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
    <div class="container tutorial-container mt-7">
        <div>
            <h1 class="text-center">Nexifyr</h1>
                <h2 class="text-center">Fontionnement des services</h2>
                <div class="text-justify mt-5">
                      <p>Nexifyr possède deux stratégies : une stratégie classique et une autre plus agressive. La stratégie agressive se caractérise par une activité plus dynamique que la stratégie classique, offrant ainsi des gains potentiels plus élevés, mais également une exposition aux risques accrue.</p>
                      <p>À titre de comparaison des différents backtests, la stratégie classique a généré 158,8% de profit en 2023.</p>
                      <p class="text-center font-weight-bold">Depuis le 1er mars 2024, nos résultats sont en réels.</p>
                      <h5 class="mb-4">Résultat 2023</h5>
                      <table>
                        <thead>
                          <tr>
                            <th>Mois</th>
                            <th>Classique</th>
                            <th>Agressive</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Janvier</td>
                            <td>23.2%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Février</td>
                            <td>10%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Mars</td>
                            <td>14.4%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Avril</td>
                            <td>14.4%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Mai</td>
                            <td>6.8%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Juin</td>
                            <td>-6.4%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Juillet</td>
                            <td>3.6%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Août</td>
                            <td>29.2%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Septembre</td>
                            <td>0.8%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Octobre</td>
                            <td>9.6%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Novembre</td>
                            <td>20%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Décembre</td>
                            <td>33.2%</td>
                            <td>0%</td>
                          </tr>
                        </tbody>
                      </table>

                      <h5 class="my-4">Résultat 2024</h5>
                      <table>
                        <thead>
                          <tr>
                            <th>Mois</th>
                            <th>Classique</th>
                            <th>Agressive</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Janvier</td>
                            <td>25.6%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>Février</td>
                            <td>22.8%</td>
                            <td>0%</td>
                          </tr>
                          <tr class="font-weight-bold">
                            <td>Mars</td>
                            <td>7.8%</td>
                            <td>13.91%</td>
                          </tr>
                          <tr class="font-weight-bold">
                            <td>Avril</td>
                            <td>0.36%</td>
                            <td>-4.39%</td>
                          </tr>
                          <tr class="font-weight-bold">
                            <td>Mai</td>
                            <td>-1.67%</td>
                            <td>5.79%</td>
                          </tr>
                          <tr class="font-weight-bold">
                            <td>Juin</td>
                            <td>14.55%</td>
                            <td>10.09%</td>
                          </tr>
                          <tr class="font-weight-bold">
                            <td>Juillet</td>
                            <td>8.34%</td>
                            <td>2.29%</td>
                          </tr>
                        </tbody>
                      </table>

                      <hr />
                      <p>Les stratégies Nexifyr ont été développées durant de nombreux mois et ont été testées sur plusieurs années. Ce travail, nécessitant un temps immense, nécessite évidemment une contribution de chaque client. Cependant, étant sûrs de nos produits, nous avons opté pour le principe qu'un client nous doit de l'argent uniquement s'il en gagne via nos services. Pas d'abonnements, ni de licences, mais seulement du Profit Sharing (partage de profit).</p>
                      <p>Le partage de profit se déroule de la façon suivante :</p>
                      <ul>
                        <li>60% des profits générés reviennent au client</li>
                        <li>40% des profits générés servent à la pérennité de Nexifyr</li>
                      </ul>
                      <h6 class="font-weight-bolder">Exemple avec un cas concret : </h6>
                      <p>Sur une semaine donnée, vous avez un capital de départ de 1000$, sur ces 1000$ la stratégie vous permet de générer 5% de profit en automatique.</p>
                      <p>5% de 1000$ donne 50$. Sur ces 50$ :</p>
                      <ul>
                        <li>30$ revient au client, soit 60% des profits</li>
                        <li>20$ revient à Nexifyr, soit 40% des profits</li>
                      </ul>
                      <h6 class="font-weight-bolder" id="explication-remuneration">Comment calculez-vous le partage de profit ? </h6>
                      <p>Pour récupérer le partage de profit, nous vous générerons, chaque samedi, une facture de la somme la somme dû, étant donné que la profit sharing fonctionne uniquement sur les profits que vous réalisez grâce à notre stratégie, vous obtenez une facture uniquement si vous avez des bénéfices. Rien ne sort de votre poche !</p>
                      <p>Pour le Profit Sharing, les semaines sont du samedi matin au vendredi soir.</p>
                      <p>Un client ayant eu son compte synchronisé avant mercredi soir recevra sa facture (s'il a généré des profits) samedi.</p>
                      <p>Un client ayant eu son compte synchronisé après mercredi soir recevra sa facture (s'il a généré des profits) le samedi d'après. Cette facture comprendra l'ensemble des bénéfices réalisés par ce client depuis sa synchronisation.</p>
                      <p>Le client reçoit une facture uniquement si son compte est bénéficiaire depuis le dernier paiement (ou depuis la création du compte).</p>

                      <h6 class="font-weight-bolder">Exemple avec un cas concret : </h6>
                      <p>Paul rejoint Nexifyr, son compte est synchronisé mardi après-midi, au samedi matin, il capitalise 20$ de profits. Il recevra une facture de 8$, soit 40% des profits générés dans la journée de samedi. Puis, chaque samedi, si son compte a effectué des profits, il recevra une nouvelle facture.</p>
                      <p>Sarah rejoint Nexifyr, son compte est synchronisé jeudi, au samedi matin, elle capitalise 5$ de profit. Elle ne recevra pas de facture cette semaine.</p>
                      <p>De ce samedi à l'autre samedi, elle effectue 30$ de profits. Elle recevra donc une facture qui comptabilisera 40% du profit réalisé sur la semaine en cours, mais également sur les jours de la semaine précédente. Ayant effectué 5$ la première semaine et 30$ la seconde, soit 35$, elle recevra une facture de 14$. 14$ correspond à 40% des 35$ de profits réalisés.</p>

                      <h6 class="font-weight-bolder">Cas entraînant un report de la facturation : </h6>
                      <p>Tom rejoint Nexifyr, son compte est synchronisé mardi après-midi. Jusqu'au samedi matin, il accumule une perte de 10 $ en profits. Par conséquent, il ne recevra aucune facture.</p>
                      <p>La semaine suivante, Tom génère un profit de 20 $. Nous calculons les profits accumulés depuis le dernier paiement ou la création du compte. Dans ce cas, en semaine 1 : -10 $ et en semaine 2 : +20 $, soit un total de 10 $ de profit. Tom recevra donc une facture de 4 $, soit 40 % des profits générés depuis le dernier paiement.</p>
                      <p>Katia rejoint Nexifyr, son compte est synchronisé mardi après-midi. D'ici samedi matin, elle accumule 30 $ de profits. Elle recevra donc une facture de 12 $, soit 40 % des profits générés au cours de cette période.</p>
                      <p>De ce samedi au samedi suivant, Katia enregistre une perte de 10 $. Par conséquent, elle ne recevra pas de facture.</p>
                      <p>La semaine suivante, Katia génère un profit de 10 $. Pour déterminer si un compte recevra une facture, nous calculons les profits accumulés depuis le dernier paiement.</p>
                      <p>Depuis le dernier paiement, Katia a subi une perte de 10 $ puis a réalisé un gain de 10 $ la semaine suivante. Le total s'élève donc à 0 $ de profit (-10 $ + 10 $ = 0 $).</p>
                      <p>Depuis le dernier paiement, Katia n'a généré aucun profit, donc elle ne recevra pas de facture.</p>
                      <p>De ce samedi au samedi suivant, Katia réalise à nouveau 10 $ de profits. Elle recevra donc une facture correspondant à 40 % des profits réalisés depuis le dernier paiement. Après avoir enregistré -10 $ la première semaine, +10 $ la seconde, et +10 $ la troisième semaine, elle atteint un total de 10 $ de profit. En conséquence, elle recevra une facture de 4 $, soit 40 % des 10 $ de profits réalisés.</p>

                      <p>Matthias rejoint Nexifyr et investit dans deux stratégies : Safe et Aggressive. Au samedi matin, il a généré un profit de 10 $ sur la stratégie Safe, mais subit une perte de 15 $ sur la stratégie Aggressive.</p>
                      <p>Pour déterminer si un compte recevra une facture, nous calculons également les bénéfices en combinant les deux stratégies. Dans ce cas, Matthias a réalisé +10 $ sur la stratégie Safe et -15 $ sur la stratégie Aggressive, soit un total de -5 $. N'ayant pas généré de bénéfice, il ne recevra pas de facture.</p>
                      <p>Pour les semaines suivantes, après avoir calculé les profits des deux stratégies réunies, le même système s'applique que dans l'exemple de Katia</p>
                      <p>Ce système protège le client, assurant qu'il ne sera facturé que lorsque l'ensemble des stratégies est profitable.</p>


                      <h6>Comment récupérez-vous le partage de profits ?</h6>
                      <p>Après avoir reçu votre facture, vous disposez de 72h pour nous envoyer les fonds, sans frais sur Bitget.</p>
                      <p>Avant d'effectuer un retrait, nous allons renforcer la sécurité de votre compte en ajoutant des méthodes de double authentification.</p>
                      <p>À partir d'un navigateur web, vous pouvez accéder à la section sécurité via <a class="lien bold" href="https://www.bitget.com/fr/account/setting" target="_blank">ce lien</a>. Sinon, via l'application, cliquez sur votre profil en haut à gauche, puis <span class="bold">Sécurité</span>.</p>
                      <div class="div-flex">
                        <div class="div-50">
                            <p>Vous pouvez ajouter deux options d'authentification. La première, une option classique que vous connaissez déjà, avec votre numéro de téléphone. La deuxième, un peu moins, est l'authentification via une application d'authentification.</p>
                            <p><span class="bold underline">Définition</span> : Google Authenticator et Authy sont des logiciels de génération de mots de passe à usage unique permettant l’authentification à deux facteurs. Les logiciels fournissent des nombres de 6 chiffres que les utilisateurs doivent donner lors de leur authentification, en plus de leurs pseudos et de leurs mots de passe.</p>
                        </div>
                        <div class="div-50 text-center">
                            <img src="../../src/assets/img/tutoBot/2FA.png" alt="Methode 2FA" class="image-width" style="width: 80%;">
                        </div>
                    </div>

                    <p>Pour configurer son téléphone, rien de plus simple, appuyez sur <span class="bold">Configurer</span>, puis indiquer votre numéro de téléphone. Cliquez bien sur <span class="bold">Envoyer</span> pour recevoir votre code par sms, puis cliquez sur <span class="bold">Soumettre</span>.</p>
                    <p>Si vous souhaitez rajouter un plus grand niveau de sécurité, vous pouvez installer une application de double authentification. Nous recommandations <span class="bold">Authy</span> à la place de Google Authenticator du fait qu'avec Authy, on peut se créer un compte, permettant de se retrouver ses accès Authy avec un autre appareil en cas de perte.</p>
                    <p>Quel que soit votre choix, ces deux applications sont téléchargable sur mobile, via l'Apple Store et le Play Store.</p>
                    <p>Pour ajouter une authentification 2FA par code, cliquez également, dans la section du <span class="bold">Code Google</span>, sur <span class="bold">Configurer</span>.</p>
                    <p>Après avoir téléchargé l'application, copiez la clé, gardez-la en lieu sûr. Par exemple, dans l'application Note de votre mobile.</p>
                    <p>Vous pouvez scanner leur QR code avec votre application 2FA ou entrer la clé.</p>
                    <p>Après cette opération, vous verrez un code à 6 chiffres qui changera toutes les 30 secondes. Vous aurez besoin de ce code pour valider chaque retrait à l'avenir.</p>
                    <p>Pour synchroniser votre application de double authentification, indiquez le code à chiffres sur Bitget et appuez sur Soumettre.</p>
                    <p>Votre méthode d'authentification est désormais configurée !</p>

                    <h6>Comment payer la facture ?</h6>
                    <p>Pour payer la facture, vous allez effectuer un retrait interne via Bitget, et donc sans frais.</p>
                    <p>Si vous êtes sur l'application Bitget, en droite à droite, cliquez sur <span class="bold">Actifs</span>, puis <span class="bold">Retrait</span>.</p>
                    <p></p>
                    <p>Si vous utilisez Bitget sur un navigateur web, rendez-vous sur la page des retraits via <a href="https://www.bitget.com/fr/asset/withdraw" target="_blank" class="bold lien">ce lien</a></p>

                    <div class="div-flex">
                        <div class="div-50">
                            <p>Sélectionnez <span class="bold">USDT</span> comme crypto monnaie.</p>
                            <p>Cliquez sur <span class="bold">Transfert interne</span>, puis <span class="bold">UID</span>. Dans le nom du compte indiquez <span class="bold">7122615292</span>.</p>
                            <p>Cliquez sur <span class="bold">Suivant</span> puis finalisez le paiement.</p>
                            <p>Vous pouvez notifier le service commercial de votre paiement en <a class="lien bold" href="\support">créant un ticket</a>.</p>
                        </div>
                        <div class="div-50 text-center">
                            <img src="../../src/assets/img/tutoBot/Internal_Transfert.png" alt="Internal transfert" class="image-width" style="width: 80%;">
                        </div>
                    </div>

                    <h6>Comment sont rémunérés les apporteurs d'affaires ?</h6>
                    <p>Les apporteurs d'affaires sont rémunéres via le Profit Sharing (partage de profit) des membres de leur réseau.</p>
                    <p>La rémunération se fait sur 5 niveaux. Le niveau 1 correspond aux clients que vous avez parrainé directement, le niveau 2 correspond aux filleuls de vos filleuls etc.</p>

                    <p style="font-weight: bold; text-decoration: underline"> Cas concret : </p>
                    <p>J'ai parrainé Samy, qui est mon premier niveau. Samy a ensuite parrainé Mélissa et Benoit, qui sont tous deux à mon deuxième niveau. Si Benoit parraine Camille, elle sera alors à mon troisième niveau, et ainsi de suite.</p>

                    <p>Rémuneration du profit sharing en fonction des niveaux  : </p>

                    <table>
                      <thead>
                          <tr>
                              <th>Niveau</th>
                              <th>Pourcentage</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>1</td>
                              <td>10%</td>
                          </tr>
                          <tr>
                              <td>2</td>
                              <td>5%</td>
                          </tr>
                          <tr>
                              <td>3</td>
                              <td>2,5%</td>
                          </tr>
                          <tr>
                              <td>4</td>
                              <td>1%</td>
                          </tr>
                          <tr>
                              <td>5</td>
                              <td>0,5%</td>
                          </tr>
                      </tbody>
                  </table>

                  <p class="mt-4">Remarque : La rémunération est effectuée après que chaque client ait réglé sa facture. Le client reçoit une facture uniquement si son compte est en bénéfice. <a href="#explication-remuneration" class="lien bold">Plus d'info ici</a>.</p>

                  <p style="font-weight: bold; text-decoration: underline" class="mt-3"> Cas concret : </p>
                  <p>Reprenons l'exemple précédent, j'ai Samy comme premier niveau. J'ai Mélissa et Benoit en deuxième niveau et Camille dans le troisième niveau.</p>
                  <p>Au début de la semaine, Samy a un capital de 1000$, Mélissa 3500$, Benoit 300$ et Camille 700$.</p>
                  <p>À la fin de la semaine, la stratégie Safe a généré un profit de 2%.</p>
                  <p>Samy a généré 20$ de profit (2% de 1000$), Mélissa 70$, Benoit 6$ et Camille 14$.</p>
                  <p>Le Profit Sharing étant de 40%, Samy va redistribuer 8$ (40% de 20$), Mélissa 28$, Benoit 2,4$ et Camille 5,6$.</p>
                  <p>Samy est de mon premier niveau, donc je vais gagner 10% de ses gains soit 2$ (10% de 20$), Mélissa et Benoit vont me rapporter chacun 5% de leur profit. Mélissa me rapporte 3,5$ (5% de 70$) et Benoit 0,3$.</p>
                  <p>Camille me rapportera 2,5% de ses profits soit 0,35$.</p>
                  <p>Mes filleuls m'ont permis de gagner un total de 6,15 $ cette semaine ! Je peux choisir de réinvestir directement ce profit dans mon robot.</p>
                  <p>L'avantage de la recommandation chez Nexifyr, c'est que vos clients peuvent augmenter votre rémunération en exploitant leur réseau. En recommandant les services, ils vous permettront de percevoir une rente plus élevée.</p>


                  <h6 id="simulation-profit-sharing">Simulateur Profit Sharing</h6>
                  <p>Indiquez le nom de l'utilisateur ainsi que son niveau et son capital. Entrez un pourcentage hebdomadaire pour calculer les profits.</p>
                  <p>Le capital minimum est de 300$.</p>

                  <div class="input-section">
                    <div style="display: flex; justify-content: space-between;">

                      <div style="display: flex; flex-direction: column">
                        <label for="name">Nom utilisateur</label>
                        <input
                          type="text"
                          v-model="newUtilisateur.nom"
                          placeholder="Nom User"
                          name="name"
                          class="form-control"
                        />
                      </div>

                      <div style="display: flex; flex-direction: column">
                        <label for="capital">Capital</label>
                        <input
                          type="number"
                          v-model.number="newUtilisateur.capital"
                          placeholder="Capital User"
                          name="capital"
                          :min="300"
                          @blur="checkCapital"
                          class="form-control"
                          
                        />
                      </div>

                      <div style="display: flex; flex-direction: column">
                        <label for="niveau">Niveau</label>
                        <select v-model.number="newUtilisateur.niveau" class="form-control">
                          <option value="1">Niveau 1 - 10%</option>
                          <option value="2">Niveau 2 - 5%</option>
                          <option value="3">Niveau 3 - 2.5%</option>
                          <option value="4">Niveau 4 - 1%</option>
                          <option value="5">Niveau 5 - 0.5%</option>
                        </select>
                      </div>

                      <div style="display: flex; flex-direction: column">
                        <label for="add" style="color: transparent">Niveau</label>
                        <button
                          style="border: 1px solid grey;"
                          ref="btnAdd"
                          @click="ajouterUtilisateur"
                          :class="{'form-control': true, 'box-shadow-class': isShadowApplied}">
                          Ajouter l'utilisateur </button>
                      </div>
                   </div>

                    <br/>
                    <div style="display: flex;">
                      <div style="display: flex; flex-direction: column">
                        <label for="Pourcentage">Pourcentage</label>
                        <input
                          type="number"
                          v-model.number="pourcentageCommon"
                          placeholder="Entrez le pourcentage common (%)"
                          name="pourcentage"
                          ref="pourcentageInput"
                          class="form-control"
                        />
                     </div>
                     <div style="display: flex; flex-direction: column" class="ml-4 mb-4">
                        <label for="win" style="color: transparent">Niveau</label>
                        <button @click="calculerGains" class="form-control" style="border: 1px solid grey;">Calculer les Gains</button>
                     </div>
                    </div>
                </div>

                <table id="resultTable">
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Niveau</th>
                      <th>Capital</th>
                      <th>Gains</th>
                      <th>Profit Sharing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, index) in utilisateurs" :key="index">
                      <td>{{ user.nom }}</td>
                      <td>Niveau {{ user.niveau }}</td>
                      <td>{{ user.capital.toFixed(2) }}$</td>
                      <td>{{ user.gain.toFixed(2) }}$</td>
                      <td>{{ user.profitSharing.toFixed(2) }}$</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3">Total des Profits</td>
                      <td>{{ totalGain.toFixed(2) }}$</td>
                      <td>{{ totalProfit.toFixed(2) }}$</td>
                    </tr>
                  </tfoot>
                </table>
            </div>
            </div>
            <app-footer />
          </div>
        </template>





 
<script>
// Importation des composants
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";

export default {
  name: "Tutorial",
  components: {
    Navbar,
    AppFooter,
  },
  data() {
    return {
      newUtilisateur: {
        nom: '',
        capital: 1000,
        niveau: 1,
      },
      utilisateurs: [],
      pourcentageCommon: 1,
      totalProfit: 0,
      totalGain: 0,
      isShadowApplied: false, 
    };
  },
  methods: {
    calculerGains() {
      if (!this.pourcentageCommon) return;

      this.totalProfit = 0;
      this.totalGain = 0;

      if (this.utilisateurs.length == 0 ){
        alert("Veuillez ajouter des utilisateurs avant de calculer les gains");
        this.isShadowApplied = true;
        return;
      }
      this.isShadowApplied = false;

      this.utilisateurs.forEach((user) => {
        user.gain = (this.pourcentageCommon / 100) * user.capital;

        switch (user.niveau) {
          case 1:
            user.profitSharing = user.gain * 0.10;
            break;
          case 2:
            user.profitSharing = user.gain * 0.05;
            break;
          case 3:
            user.profitSharing = user.gain * 0.025;
            break;
          case 4:
            user.profitSharing = user.gain * 0.01;
            break;
          case 5:
            user.profitSharing = user.gain * 0.005;
            break;
          default:
            user.profitSharing = 0;
            break;
        }

        this.totalProfit += user.profitSharing;
        this.totalGain += user.gain;
      });
    },
    ajouterUtilisateur() {
      if (
        this.newUtilisateur.nom &&
        this.newUtilisateur.capital > 0 &&
        this.newUtilisateur.niveau &&
        this.pourcentageCommon > 0
      ) {
        this.utilisateurs.push({
          ...this.newUtilisateur,
          gain: 0,
          profitSharing: 0,
        });
        // Réinitialiser les champs
        this.newUtilisateur.nom = '';
        this.newUtilisateur.capital = 1000;
        this.newUtilisateur.niveau = 1;

        this.calculerGains();
      }
      else {
        // Afficher un message d'erreur et mettre le focus sur l'input du pourcentage
        alert("Veuillez définir un pourcentage avant d'ajouter un utilisateur.");
        this.$nextTick(() => {
          this.$refs.pourcentageInput.focus();
        });
      }
    },
    checkCapital() {
      if (this.newUtilisateur.capital < 300) {
        this.newUtilisateur.capital = 300;
      }
    },
    
  },
  // Lifecycle hooks pour gérer l'état global
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
  },
};
</script>
<style scoped>

body {
    font-family: "DM Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.div-flex {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    text-align: justify;
}

.div-50 {
    width: 50%;
    text-align: justify;
}

.image-width {
    width: 50%;
    margin: auto;
}

.text-center {
    text-align: center;
}

.bold {
    font-weight: bold;
}
.underline {
    text-decoration: underline;
}

.lien {
    color: blue;
    text-decoration: underline;
}

.tutorial-container {
    margin-top: 5%;
}

/* Add this CSS to your stylesheet or within a <style> tag in the head of your HTML */

table {
  width: 100%; /* Adjust the table to full width */
  border-collapse: separate; /* Required for border-spacing to work */
  border-spacing: 20px 0; /* 20 pixels spacing horizontally, 0 vertically */
  margin: 0 auto; /* Centers the table on the page */
}

th, td {
  text-align: left; /* Aligns text to the left */
  padding: 10px; /* Adds padding inside the cells, adjust as needed */
}

/* Optional: Styles to improve the visual appearance of the table */
th {
  background-color: #f2f2f2; /* Light grey background for headers */
  font-weight: bold; /* Make header text bold */
}

td {
  background-color: #ffffff; /* White background for cells */
}

/* Adds a subtle border to the cells */
th, td {
  border: 1px solid #dddddd;
}

/* Adds hover effect for rows */
tr:hover {
  background-color: #f5f5f5;
}
.box-shadow-class {
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

/* Media query pour les écrans jusqu'à 768px de large */
@media (max-width: 768px) {
  .tutorial-container {
    margin-top: 10%; 
  }
  .div-flex{
    flex-direction: column-reverse;
  }
  .div-50{
    width: 100%;
    margin: 10px 0;
    text-align: center;
  }
  p, h3, h2, h1{
    text-align: center;
  }
}
</style>