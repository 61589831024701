<template>
    <div class="card card-profile">
        <img src="../../assets/img/bg-profile.jpg" alt="Image placeholder" class="card-img-top" />
        <div class="row justify-content-center">
            <div class="col-4 col-lg-4 order-lg-2">
                <div class="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                    <a href="javascript:;">
                        <img src="../../assets/img/Avatar.webp"
                            class="rounded-circle img-fluid border border-2 border-white" />
                    </a>
                </div>
            </div>
        </div>
        <div class="card-header text-center border-0 pt-0 pt-lg-2 pb-4 pb-lg-3">
            <div class="d-flex justify-content-between">
                <!-- <a href="/network" class="btn btn-sm btn-info mb-0 d-none d-lg-block">Parrainages</a> -->
                <a href="/profile" class="btn btn-sm btn-dark float-right mb-0 d-none d-lg-block">Mon Profil</a>
            </div>
        </div>
        <div class="card-body pt-0">
            <!-- <div class="row">
                <div class="col">
                    <div class="d-flex justify-content-center">
                        <div class="d-grid text-center mx-4">
                            <span class="text-lg font-weight-bolder">2</span>
                            <span class="text-sm opacity-8">Crypto activée</span>
                        </div>
                        <div class="d-grid text-center">
                            <span class="text-lg font-weight-bolder">0 $</span>
                            <span class="text-sm opacity-8">Revenus</span>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="text-center mt-4" v-for="(account, index) in accounts" :key="index">
                <div v-if="account.strategy">
                    <p class="font-weight-bold">Robot Stratégie : {{ account.strategy }}</p>
                        <p>
                            <span v-if="account.admin_validated === true && account.valid_connection === true"
                                class="badge badge-sm bg-gradient-success">Actif</span>
                            <span v-else-if="account.admin_validated !== true && account.valid_connection === true"
                                class="badge badge-sm bg-gradient-warning">En attente de validation</span>
                            <span v-else
                                class="badge badge-sm bg-gradient-secondary">Inactif</span>
                        </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyRobotCard",
    props: {
        accounts: Array,
    },
    methods: {
        formatDate(dateString) {
            if (!dateString) {
                return ""; // Retourne une chaîne vide si dateString est falsy (par exemple, null, undefined, etc.)
            }

            const date = new Date(dateString);
            
            // Vérifie si la date est invalide
            if (isNaN(date.getTime())) {
                return ""; // Retourne une chaîne vide si la date est invalide
            }

            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retourne un index à base zéro
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
    }


};
</script>