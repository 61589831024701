// store/index.js
import { createStore } from "vuex";
import axios from 'axios';
import router from '@/router';

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    isAdmin: false,
    apiError: false,
    apiChecked: false, // Ajout de la propriété apiChecked
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      state.isNavFixed = !state.isNavFixed;
    },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setApiError(state, value) {
      state.apiError = value;
    },
    setApiChecked(state, value) {
      state.apiChecked = value;
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    updateIsAdmin({ commit }, isAdmin) {
      commit('setIsAdmin', isAdmin);
    },
    async checkApiStatus({ commit }) {
      try {
        await axios.get("/ping");
        commit('setApiError', false);
      } catch (error) {
        commit('setApiError', true);
        router.push("/maintenance");
      } finally {
        commit('setApiChecked', true);
      }
    },
  },
  getters: {
    apiError: state => state.apiError,
    apiChecked: state => state.apiChecked,
  }
});
