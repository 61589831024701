<template>
    <!-- -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- -->
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mx-auto mt-2 mb-4 text-center col-lg-8">
     
          <!--<a
            href=""
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-discord"></span>
          </a>-->
          <a
            href="https://t.me/+jgWthEZmkzVhMGY0"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-telegram"></span>
          </a>
          <a href="mailto:contact@nexifyr.fr" target="_blank" class="text-secondary me-xl-4 me-4">
            <span class="text-lg fa fa-envelope"></span> 
          </a>
          <a
            href="https://t.me/nexifyr"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fas fa-headset"></span>
          </a>

          <!--<a
            href="javascript:;"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-instagram"></span>
          </a>-->
         
        </div>
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            Nexifyr - Tous droits réservés © {{ new Date().getFullYear() }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
