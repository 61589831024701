<template>
  <div class="card h-100 p-3">
    <div
      class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
      :style="{backgroundImage: 'url(' + require('@/assets/img/ivancik.jpg') + ')'}"
    >
      <span class="mask bg-gradient-dark"></span>
      <div class="card-body position-relative z-index-1 p-3 h-100">
        <div class="d-flex flex-column h-100">
          <h5 class="text-white font-weight-bolder mb-4 pt-2">
            Votre abonnement -

            <!-- sI C'est Active alors affiche en vert -->
            <span v-if="userSubscription && userSubscription.status === 'Active'" class="text-success">
              Actif
            </span>
          
            <span v-else-if="userSubscription && (!userSubscription.status || userSubscription.status === 'Inactive')" class="text-danger">
              Inactif
            </span>
            <span v-else class="text-warning">
              En attente
            </span>
          </h5>

          <p class="text-white" v-if="userSubscription && userSubscription.status === 'Pending'">
            Votre abonnement est en attente de validation.
          </p>
          <!-- Si l'abonnement est actif ; -->
          <p class="text-white" v-if="userSubscription && userSubscription.status === 'Active' || userSubscription.status === 'Inactive'">
            Date de fin : {{ userSubscription.subscription_expiration }}
          </p>


          <p class="text-white">
            Vous pouvez prolonger ou renouveller votre abonnement à tout moment.
          </p>

          

          <p class="text-white">
            Argent parrainages disponible : 100€
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiService.js";

export default {
  name: "rocket-card",
  data() {
    return {
      userSubscription: {
        status: "",
        subscription_date: "",
        subscription_expiration: "",
        subscription_name: "",
      },
    };
  },
  methods: {
    async getUserSubscription() {
        try {
          const response = await apiClient.get("/subscription/getUserSubscription");
          this.userSubscription = response.data;
          // console.log(this.userSubscription);
        } catch (error) {
          // console.log(error);
        }
      },
  },
  mounted() {
    this.getUserSubscription();
  },
};
</script>
