<template>
  <main>
      <div class="py-4 container-fluid">
          <div class="row">
              <div class="col-md-12">
                  <div class="card">
                      <div class="card-body" style="width: auto;">
                          <div>
                              <button class="btn mb-0 btn-success btn-sm" @click="goToAdminDashboard()">Retour au
                                  Dashboard</button>
                              <p class="text-uppercase text-sm mt-4">Tickets</p>
                              <div class="table-responsive">
                                  <nav aria-label="Page navigation example"
                                      class="d-flex justify-content-center align-items-center">
                                      <span class="mr-3">Page {{ currentPage }} sur {{ totalPages }}</span>
                                      <ul class="pagination mb-0">
                                          <li class="page-item" :class="{ disabled: currentPage <= 1 }">
                                              <a class="page-link" href="#" aria-label="Previous"
                                                  @click.prevent="currentPage--">
                                                  <span aria-hidden="true">&laquo;</span>
                                              </a>
                                          </li>
                                          <li class="page-item" :class="{ active: n === currentPage }"
                                              v-for="n in pagesToShow()" :key="n">
                                              <a class="page-link" href="#" @click.prevent="goToPage(n)">{{ n }}</a>
                                          </li>

                                          <li class="page-item" :class="{ disabled: currentPage >= totalPages }">
                                              <a class="page-link" href="#" aria-label="Next"
                                                  @click.prevent="currentPage++">
                                                  <span aria-hidden="true">&raquo;</span>
                                              </a>
                                          </li>
                                      </ul>
                                  </nav>

                                  <table class="table">
                                      <thead>
                                          <tr>
                                              <th>ID</th>
                                              <th>Pseudo</th>
                                              <th>Email</th>
                                              <th>Nom</th>
                                              <th>Etat</th>
                                              <th>Balance</th>
                                              <th>Parrain</th>
                                              <th>Dernière connexion</th>
                                              <th></th>
                                              <th></th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="user in paginatedUsers" :key="user.id">
                                              <td>{{ user.id }}</td>
                                              <td>{{ user.pseudo }}</td>
                                              <td>{{ user.email }}</td>
                                              <td>{{ user.last_name }} {{ user.first_name }}</td>
                                              <td v-if="user.admin_validated == true && user.accountBalance >= 250">
                                                  <span class="badge badge-success">Actif</span>
                                              </td>
                                              <td v-else-if="user.valid_connection == true && user.accountBalance == 0">
                                                  <span class="badge badge-info">En attente de dépôt</span>
                                                </td>
                                               <td v-else-if="user.admin_validated == true && user.accountBalance < 250 || user.valid_connection == true && user.accountBalance < 250">
                                                  <span class="badge badge-info">Solde insuffisant</span>
                                                </td>
                                                <td v-else-if="user.valid_connection == true && user.leverage_updated == false">
                                                  <span class="badge badge-warning">En attente de mise à jour du levier</span>
                                                </td>
                                                <td v-else-if="user.valid_connection == false && user.date_key != null">
                                                  <span class="badge badge-danger">Clés API non valides</span>
                                                </td>
                                                <td v-else-if="user.valid_connection == true && user.admin_validated == false">
                                                  <span class="badge badge-warning">En attente de validation Admin</span>
                                                </td>
                                                <td v-else>
                                                  <span class="badge badge-secondary">Inactif</span>
                                                </td>
                                                <td>{{ user.accountBalance }}</td>
                                                <td>{{ user.sponsor_pseudo }}</td>
                                                <td>{{ formatDateFR(user.date_last_login) }}</td>

                                              <td><button class="btn mb-0 btn-success btn-sm"
                                                      @click="goToUserDetail(user.id)">Détail</button></td>
                                              <td><button class="btn mb-0 btn-success btn-sm"
                                                      @click="createTicketToUser(user.id)">Ticket</button></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
import router from "@/router/index.js";


export default {
  name: "support",
  data() {
      return {
          showMenu: false,
          users: [],
          currentPage: 1,
          totalPages: 0,
          perPage: 15,
      };
  },
  setup() {
      const toast = useToast();

      return { toast };
  },
  computed: {
      paginatedUsers() {
          const start = (this.currentPage - 1) * this.perPage;
          const end = start + this.perPage;
          return this.users.slice(start, end);
      }
  },
  watch: {
      users() {
          this.totalPages = Math.ceil(this.users.length / this.perPage);
      }
  },
  methods: {
      async getAllUsers() {
          try {
              const response = await apiClient.get("/admin/getAllUsers");
              if (response.status === 200) {
                  this.users = response.data;
              }
          } catch (error) {
              this.toast.error(error.response.data.error);
          }
      },

      goToUserDetail(userId) {
          router.push("/user-detail/" + userId);
      },
      createTicketToUser(userId) {
          router.push("/create-ticket/" + userId);
      },
      goToAdminDashboard() {
          router.push("/admin-dashboard");
      },
      formatDateFR(value) {
          if (value) {
              const date = new Date(value);
              return new Intl.DateTimeFormat('fr-FR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  timeZone: 'UTC' // Ajoutez cette ligne si vous voulez garder l'heure en UTC
              }).format(date);
          }
          return '';
      },
      goToPage(n) {
          this.currentPage = n;
      },
      pagesToShow() {
          const pages = [];
          // Déterminez le début de l'intervalle en s'assurant qu'il est >= 1
          let start = Math.max(this.currentPage - 2, 1);
          // Déterminez la fin de l'intervalle en s'assurant qu'il est <= totalPages
          let end = Math.min(start + 4, this.totalPages);

          // Si nous sommes trop proches de la fin, ajustez le début pour montrer toujours 5 éléments (si possible)
          if (this.totalPages - this.currentPage < 2) {
              start = Math.max(this.totalPages - 4, 1);
          }

          // Générez les numéros de page dans l'intervalle
          for (let i = start; i <= end; i++) {
              pages.push(i);
          }
          return pages;
      }

  },
  mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
      setTooltip();
      this.getAllUsers();
  },
  beforeMount() {
      this.$store.state.showNavbar = false;
      this.$store.state.showFooter = true;
      this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
      this.$store.state.isAbsolute = false;
      this.$store.state.showNavbar = true;
      this.$store.state.showFooter = true;
      this.$store.state.hideConfigButton = false;
  }
};
</script>
<style>
.table thead th {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.btn-no-pointer {
  cursor: default !important;
}
</style>