<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div v-if="!this.$store.state.isAdmin" class="card">
            <div class="card-body">
              <p class="text-uppercase text-sm">Création d'un ticket</p>
              <div class="row">
                <div class="col-md-4">
                  <label for="category-select" class="form-control-label">Catégorie</label>
                  <div class="select-container">
                    <select id="category-select" class="form-control form-group" v-model="content.category">
                      <option value="">Sélectionnez une catégorie</option>
                      <option v-for="category in categories" :key="category" :value="category">
                        {{ category }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-8">
                  <label for="sujet-input" class="form-control-label">Sujet</label>
                  <input id="sujet-input" class="form-control form-group" type="text" v-model="content.subject" />
                </div>
                <div class="col-md-12">
                  <label for="message-input" class="form-control-label">Message</label>
                  <textarea id="message-input" class="form-control form-group" v-model="content.message" rows="4"></textarea>
                </div>
              </div>
              <div class="text-center">
                <argon-button color="success" size="sm" type="submit" @click.prevent="createTicket">Créer un
                  ticket</argon-button>
              </div>
            </div>
          </div>
          <div class="card mt-4">
            <div class="card-body">
              <div class="mt-4">
                <p class="text-uppercase text-sm">Tickets</p>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th v-if="this.$store.state.isAdmin">Pseudo</th>
                        <th>Catégorie</th>
                        <th>Sujet</th>
                        <th>Date Création</th>
                        <th>Etat</th>
                        <th></th>
                        <th v-if="this.$store.state.isAdmin">Changer d'état</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="ticket in tickets" :key="ticket.id">
                        <td>{{ ticket.id }}</td>
                        <td v-if="this.$store.state.isAdmin">{{ ticket.pseudo }}</td>
                        <td>{{ ticket.category }}</td>
                        <td>{{ ticket.subject }}</td>
                        <td>{{ ticket.date }}</td>
                        <td>{{ ticket.status }}</td>
                        <td><button class="btn mb-0 btn-success btn-sm"
                            @click="goToTicketDetail(ticket.id)">Détail</button></td>
                        <td v-if="this.$store.state.isAdmin">
                          <select class="form-control form-group" v-model="ticket.status" @change="changeTicketStatus(ticket.id)">
                            <option value="Open">Ouvert</option>
                            <option value="In Progress">En cours</option>
                            <option value="Closed">Fermé</option>
                            <option value="Resolved">Résolu</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonButton from "@/components/ArgonButton.vue";
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
// import router from "@/router/index.js";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "support",
  data() {
    return {
      showMenu: false,
      user: {
        pseudo: "",
        email: "",
      },
      content: {
        category: "",
        subject: "",
        message: "",
      },
      tickets: [],
      categories: ["Robot", "Compte", "Profit Sharing", "Problème technique", "Autre"],
    };
  },
  components: { ArgonButton },
  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    async getUserTicket() {
      try {
        const response = await apiClient.get("/support/getTicketsByUserId");
        if (response.status === 200) {
          this.tickets = response.data;
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    async createTicket() {
      try {

        if (!this.content.category || !this.content.subject || !this.content.message) {
          this.toast.error("Veuillez remplir tous les champs");
          return;
        }

        const response = await apiClient.post("support/createTicket", {
          category: this.content.category,
          subject: this.content.subject,
          message: this.content.message,
        });
        if (response.status === 200) {
          this.toast.success(response.data.message);
          this.getUserTicket();
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    goToTicketDetail(ticketId) {
      this.$router.push({ name: 'SupportDetail', query: { ticketId: ticketId } });
    },

    async changeTicketStatus(ticketId) {
      try {
        const response = await apiClient.put("support/updateTicketStatus", {
          ticket_id: ticketId,
          status: this.tickets.find((ticket) => ticket.id === ticketId).status,
        });
        if (response.status === 200) {
          this.toast.success(response.data.message);
          this.getUserTicket()
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },

  },
  mounted() {
    this.$store.state.isAbsolute = false;
    setNavPills();
    setTooltip();
    this.getUserTicket();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
<style>
.table thead th {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
</style>