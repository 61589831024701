<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-4">
          <MyRobotCard :accounts="accounts" />
        </div>
        <div class="col-md-8">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Mon Robot</p>
              </div>
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">Informations générales</p>
              <div v-for="(account, index) in accounts" :key="index">
                <div class="row mt-3" v-if="account.valid_key">
                  <div class="col-md-7">
                    <label for="api-key-input-show" class="form-control-label">Clé Api Publique</label>
                    <input id="api-key-input-show" class="form-control form-group" type="text" v-model="account.api_key" disabled />
                  </div>
                  <div class="col-md-5">
                    <label for="strategy-input-show" class="form-control-label">Stratégie</label>
                    <input id="strategy-input-show" class="form-control form-group" type="text" v-model="account.strategy" disabled />
                  </div>
                  <div class="col-md-12 d-flex align-items-center mt-3">
                    <argon-button color="danger" size="sm" type="submit" @click.prevent="deleteApiKey(index)">
                      Supprimer
                    </argon-button>
                    <div class="form-check form-switch" style="margin-left: 10px;">
                      <input type="checkbox" class="form-check-input" id="activate-trading" v-model="account.valid_connection" @click="updateTradingStatus(index)" />
                      <label class="form-check-label" for="activate-trading">Activer le trading</label>
                    </div>

                  </div>
                </div>

                <div class="row mt-3" v-if="!account.valid_key">
                  <div class="col-md-6">
                    <label for="api-key-input" class="form-control-label">Clé API Publique</label>
                    <input id="api-key-input" class="form-control form-group" type="text" v-model="account.api_key" required />
                  </div>
                  <div class="col-md-6">
                    <label for="api-secret-key-input" class="form-control-label">Clé secrète</label>
                    <input id="api-secret-key-input" class="form-control form-group" type="password"
                      v-model="account.apiSecretKey" required />
                  </div>
                  <div class="col-md-6">
                    <label for="passPhrase-input" class="form-control-label">Phrase secrète</label>
                    <input id="passPhrase-input" class="form-control form-group" type="password" v-model="account.passPhrase" required />
                  </div>
                  <div class="col-md-6">
                    <label for="strategy-input" class="form-control-label">Stratégie</label>
                    <select id="strategy-input" style="appearance: auto;" class="form-control form-group" v-model="account.strategy" required>
                      <option value="" disabled selected>Veuillez choisir une stratégie</option>
                      <option value="Safe">Safe</option>
                      <option value="Aggressive">Agressive</option>
                    </select>
                  </div>
                  <argon-button color="primary" size="sm" type="submit" @click="addApiKey(index)">
                      Ajouter
                  </argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import MyRobotCard from "./components/MyRobotCard.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
// import router from "@/router/index.js";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "MyRobot",
  data() {
    return {
      showMenu: false,
      accounts: []
    };
  },
  components: { 
    ArgonButton,
    MyRobotCard,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    async getUserApiKeys() {
      try {
        const response = await apiClient.get("/tradingAccount/getApiKeys");
        if (response.status === 200) {
          this.accounts = response.data.tradingAccountsDatas
          for (let i = 0; i < this.accounts.length; i++) {
            if (this.accounts[i].strategy == null) {
              this.accounts[i].strategy = '';
            }

            if (this.accounts[i].strategy == 'Aggressive') {
              this.accounts[i].strategy = 'Agressive';
            }

            if (this.accounts[i].api_key) {
              this.accounts[i].valid_key = true;
            } else {
              this.accounts[i].valid_key = false;
            }
          }
          if (this.accounts.length === 1 && this.accounts[0].api_key !== null) {
            // On rajoute un objet pour pouvoir ajouter un nouveau compte
            this.accounts.push({
              api_key: '',
              apiSecretKey: '',
              passPhrase: '',
              valid_connection: false,
              admin_validated: false,
              strategy: '',
              id: null,
              valid_key: false,
            })
          }
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    async addApiKey(accountIndex) {
      try {
        // On vérifie si le formulaire est valide
        if (!this.accounts[accountIndex].api_key || !this.accounts[accountIndex].apiSecretKey || !this.accounts[accountIndex].passPhrase || !this.accounts[accountIndex].strategy) {
          this.toast.error("Veuillez remplir tous les champs");
          return;
        }

        // Si c'est le deuxieme compte, on vérifie que les stratégies sont différentes
        if (this.accounts.length >= 2) {
          if (this.accounts[0].strategy === this.accounts[1].strategy) {
              this.toast.error("Vous ne pouvez pas avoir deux comptes avec la même stratégie");
              return;
          }
          if (this.accounts[0].api_key === this.accounts[1].api_key) {
            this.toast.error("Vous ne pouvez pas avoir deux comptes avec la même clé API");
            return;
          }
        }

        const account = this.accounts[accountIndex];

        const response = await apiClient.post("tradingAccount/addApiKeys", {
          api_key: account.api_key,
          secret_key: account.apiSecretKey,
          api_passphrase: account.passPhrase,
          activate_trading: true,
          strategy: account.strategy,
          trading_account_id: account.id,
        });
        if (response.status === 200) {
          this.toast.success(response.data.message);
          this.getUserApiKeys();
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    async deleteApiKey(accountIndex) {
      try {
        const account = this.accounts[accountIndex];

        if (!account.valid_key || !account.id) {
          this.toast.error("Vous ne pouvez pas supprimer un compte qui n'existe pas");
          return;
        }
        const response = await apiClient.delete("tradingAccount/deleteApiKeys", {
          data: { trading_account_id: account.id },
        });
        if (response.status === 200) {
          this.toast.success(response.data.message);
          this.getUserApiKeys();
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    async updateTradingStatus(accountIndex) {
      try {
        const account = this.accounts[accountIndex];
        // Change the value of the checkbox
        if (account.valid_connection === true) {
          account.valid_connection = false;
        } else {
          account.valid_connection = true;
        }

        const response = await apiClient.put("tradingAccount/updateTradingStatus", {
          activate_trading: account.valid_connection,
          trading_account_id: account.id,
        });
        if (response.status === 200) {
          this.toast.success(response.data.message);
          this.getUserApiKeys();
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    }
  },
  mounted() {
    this.$store.state.isAbsolute = false;
    setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
    this.getUserApiKeys();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
