<template>
    <div class="d-flex justify-content-center align-items-center vh-100">
      <div class="text-center p-5 bg-white rounded shadow">
        <h1>Maintenance en cours</h1>
        <p>Le site est actuellement en maintenance.</p>
        <p>Veuillez réessayer plus tard.</p>
      </div>
    </div>
</template>

<script>
export default {
  name: "MaintenancePage",
  created() {
      this.$store.state.hideConfigButton = true;
      this.$store.state.showNavbar = false;
      this.$store.state.showSidenav = false;
      this.$store.state.showFooter = false;
    },
};
</script>


<style scoped>
.maintenance-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>