<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain" v-if="!forgetPassword">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Nexifyr</h4>
                  <p class="mb-0">Connexion</p>
                </div>
                <div class="card-body">
                  <form role="form">
                    <div class="mb-3">
                        <label for="email" class="form-label">Email <span class="text-danger">*</span></label>
                        <input type="email" class="form-group form-control" id="email" placeholder="Email" aria-label="Email" v-model="email"/>
                      </div>
                      <label for="password" class="form-label">Mot de passe <span class="text-danger">*</span></label>
                      <div class="mb-3 position-relative">
                        <input :type="showPassword ? 'text' : 'password'" class="form-group form-control" id="password" placeholder="Mot de passe" aria-label="Password" v-model="password"/>
                        <span class="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer" @click="togglePasswordVisibility">
                          <i :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                        </span>
                    </div>

                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        @click.prevent="signin"
                      >
                        Connexion
                      </argon-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Pas encore inscrit ?
                    <a href="/signup" class="text-success text-gradient font-weight-bold">Inscription</a>
                  </p>
                  <p>
                    <a href='#' class="text-sm font-weight-bold" @click="forgetPasswordForm(true)">Mot de passe oublié ?</a>
                  </p>
                </div>
              </div>
              <div class="card card-plain" v-if="forgetPassword">
              <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Nexifyr</h4>
                  <p class="mb-0">Mot de Passe oublié</p>
                </div>
                <div class="card-body">
                  <form role="form">
                    <div class="mb-3">
                      <label for="email_reset" class="form-label">Email <span class="text-danger">*</span></label>
                      <input type="email" class="form-group form-control" id="email_reset" placeholder="Email" aria-label="Email" v-model="email_reset"/>
                    </div>
                    <div class="text-center">
                      <argon-button
                        class="mt-2 mx-4"
                        variant="gradient"
                        color="success"
                        size="md"
                        @click.prevent="sendNewPassword"
                      >
                        Envoyer
                      </argon-button>
                      <argon-button
                        class="mt-2"
                        variant="gradient"
                        color="danger"
                        size="md"
                        @click.prevent="forgetPasswordForm(false)"
                      >
                        Annuler
                      </argon-button>
                    </div>
                  </form>
                  </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg');
          background-size: cover;"
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >"Attention is the new currency"</h4>
                <p
                  class="text-white position-relative"
                >The more effortless the writing looks, the more effort the writer actually put into the process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
import router from "@/router/index.js";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "signin",
  components: {
    Navbar,
    ArgonButton,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      forgetPassword: false,
      email_reset: "",
    };
  },
  methods: {
    async signin() {
      try {
        const response = await apiClient.post("/auth/login", {
          email: this.email,
          password: this.password,
        });
        if (response.status === 200) {
          this.toast.success("Connexion réussie");
          router.push({ name: "Dashboard" });
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    }, 
    // Fonction ou j'envoie true ou false pour afficher le formulaire de mot de passe oublié
    forgetPasswordForm(value) {
      this.forgetPassword = value;
      this.email_reset = "";
      this.password = "";
      this.email = "";
    },
    async sendNewPassword() {
      try {
        const response = await apiClient.post("/auth/sendResetPasswordCode", {
          email: this.email_reset,
        });
        if (response.status === 200) {
          this.toast.success("Un nouveau mot de passe vous a été envoyé par email");
          this.forgetPassword = false;
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style>
  @media (max-width: 600px) {
  input:focus, input:hover {
    font-size: 17px; /* Empêche le zoom sur les mobiles */
  }
}
</style>