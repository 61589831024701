<template>
  <main>
    <div class="container-fluid">
      <div class="page-header min-height-300" style="
          background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
          margin-right: -24px;
          margin-left: -34%;
        ">
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-4">
          <profile-card />
        </div>
        <div class="col-md-8">
          <div class="card" v-if="!changePassword">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Editer le profil</p>
                <argon-button color="success" size="sm" class="ms-auto" @click="changePasswordForm(true)">Changer Mot de
                  Passe</argon-button>
              </div>
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">Informations générales</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="pseudo-input" class="form-control-label">Pseudo</label>
                  <input id="pseudo-input" class="form-control form-group" type="text" v-model="user.pseudo" disabled />
                </div>
                <div class="col-md-6">
                  <label for="email-input" class="form-control-label">Email</label>
                  <input id="email-input" class="form-control form-group" type="text" v-model="user.email" disabled />
                </div>
                <div class="col-md-6">
                  <label for="first-name-input" class="form-control-label">Prénom</label>
                  <input id="first-name-input" class="form-control form-group" type="text" v-model="user.first_name" />
                </div>
                <div class="col-md-6">
                  <label for="last-name-input" class="form-control-label">Nom</label>
                  <input id="last-name-input" class="form-control form-group" type="text" v-model="user.last_name" />
                </div>

              </div>
              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">Informations de contact</p>
              <div class="row">
                <div class="col-md-12">
                  <label for="address-input" class="form-control-label">Adresse</label>
                  <input id="address-input" class="form-control form-group" type="text" v-model="user.address" />
                </div>
                <div class="col-md-6">
                  <label for="city-input" class="form-control-label">Ville</label>
                  <input id="city-input" class="form-control form-group" type="text" v-model="user.city" />
                </div>
                <div class="col-md-6">
                  <label for="country-input" class="form-control-label">Pays</label>
                  <input id="country-input" class="form-control form-group" type="text" v-model="user.country" />
                </div>
                <div class="col-md-6">
                  <label for="postal-code-input" class="form-control-label">Code Postal</label>
                  <input id="postal-code-input" class="form-control form-group" type="text" v-model="user.postal_code" />
                </div>
                <div class="col-md-6">
                  <label for="phone-input" class="form-control-label">Téléphone</label>
                  <input id="phone-input" class="form-control form-group" type="text" v-model="user.phone" />
                </div>
              </div>
              <hr class="horizontal dark" />
              <div class="text-center">
                <argon-button color="success" size="sm" type="submit"
                  @click.prevent="updateProfil">Enregistrer</argon-button>
              </div>
            </div>
          </div>
          <div class="card d-flex align-items-center justify-content-center" v-if="changePassword">
            <div class="col-md-6 mt-5">
              <label for="new-password-input" class="form-control-label">Nouveau Mot de Passe</label>
              <input id="new-password-input" class="form-control form-group" type="password" v-model="new_password" />
            </div>
            <div class="col-md-6">
              <label for="confirm-new-password-input" class="form-control-label">Confirmer Mot de Passe</label>
              <input id="confirm-new-password-input" class="form-control form-group" type="password"
                v-model="confirm_new_password" />
            </div>
            <hr class="horizontal dark" />
            <div class="text-center mb-5">
              <argon-button color="success" size="sm" type="submit" class="mx-4"
                @click.prevent="updatePassword">Enregistrer</argon-button>
                <argon-button color="danger" size="sm" type="submit"
                @click.prevent="changePasswordForm(false)">Annuler</argon-button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ProfileCard from "./components/ProfileCard.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
// import router from "@/router/index.js";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "profile",
  data() {
    return {
      showMenu: false,
      changePassword: false,
      new_password: "",
      confirm_new_password: "",
      user: {
        pseudo: "",
        email: "",
        first_name: "",
        last_name: "",
        address: "",
        city: "",
        country: "",
        postal_code: "",
        phone: "",
      },
      originalUser: {
        pseudo: "",
        email: "",
        first_name: "",
        last_name: "",
        address: "",
        city: "",
        country: "",
        postal_code: "",
        phone: "",
      },
    };
  },
  components: { ProfileCard, ArgonButton },
  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    async getUserProfile() {
      try {
        const response = await apiClient.get("/users/getMyProfile");
        if (response.status === 200) {
          this.user = response.data;
          this.originalUser = JSON.parse(JSON.stringify(response.data)); // Copie indépendante
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    async updateProfil() {
      try {
        // Vérifie que des informations ont été modifiées avant de faire la requête
        if (JSON.stringify(this.user) === JSON.stringify(this.originalUser)) {
          this.toast.warning("Aucune information n'a été modifiée !");
          return;
        }

        const response = await apiClient.put("/users/updateProfile", {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          address: this.user.address,
          city: this.user.city,
          country: this.user.country,
          postal_code: this.user.postal_code,
          phone: this.user.phone,
        });
        if (response.status === 200) {
          this.toast.success("Profil mis à jour !");
          this.originalUser = JSON.parse(JSON.stringify(this.user)); // Copie indépendante
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
    changePasswordForm(value) {
      this.changePassword = value;
      this.new_password = "";
      this.confirm_new_password = "";
    },
    async updatePassword() {
      try {
        if (this.new_password !== this.confirm_new_password || this.new_password === "" || this.confirm_new_password === "") {
          this.toast.error("Les mots de passe ne correspondent pas !");
          return;
        }
        const response = await apiClient.put("/users/updatePassword", {
          new_password: this.new_password,
          confirm_new_password: this.confirm_new_password,
        });
        if (response.status === 200) {
          this.toast.success("Mot de passe mis à jour !");
          this.changePassword = false;
        }
      } catch (error) {        
        this.toast.error(error.response.data.error);
      }
    },
  },
  mounted() {
    this.$store.state.isAbsolute = false;
    setNavPills();
    setTooltip();
    this.getUserProfile();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
