<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Nexifyr</h4>
                  <p class="mb-0">Nouveau Mot De Passe</p>
                </div>
                <div class="card-body">
                  <form role="form">
                    <div class="mb-3">
                      <input type="password" class="form-group form-control" placeholder="Mot de passe" aria-label="Password" v-model="password"/>
                    </div>
                    <div class="mb-3">
                      <input type="password" class="form-group form-control" placeholder="Confirmer Mot de passe" aria-label="Password" v-model="confirmPassword"/>
                    </div>

                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        @click.prevent="resetPassword"
                      >
                        Créer un nouveau mot de passe
                      </argon-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg');
          background-size: cover;"
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >"Attention is the new currency"</h4>
                <p
                  class="text-white position-relative"
                >The more effortless the writing looks, the more effort the writer actually put into the process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
import router from "@/router/index.js";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "ResetPassword",
  components: {
    Navbar,
    ArgonButton,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
        password: "",
        confirmPassword: "",
        // On récupère le token dans l'url
        code: this.$route.query.code,
        };
  },
  methods: {
    async resetPassword() {
      if (this.password != this.confirmPassword || this.password.length < 8 || this.confirmPassword.length < 8) {
        this.toast.error("Les mots de passe ne correspondent pas ou sont trop courts");
        return;
      }
      if (this.code == null) {
        this.toast.error("Veuillez utiliser le lien de réinitialisation de mot de passe qui vous a été envoyé par mail");
        return;
      }
      apiClient
        .put("/users/resetPassword", {
          password: this.password,
          code: this.code,
        })
        .then((response) => {
            if (response.status === 200) {
                this.toast.success("Mot de passe modifié avec succès");
                router.push("/signin");
            }
        })
        .catch((error) => {
          this.toast.error(error.response.data.error);
        });
    },
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
