<template>
  <div class="py-4 container-fluid" v-if="!isLoading">
    <div class="row">
      <div class="col-lg-12">
        <div class="row d-flex">
          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <card class="h-100"
              :title="stats.money.title"
              :values="stats.money.values"
              :percentage="stats.money.percentage"
              :iconClass="stats.money.iconClass"
              :iconBackground="stats.money.iconBackground"
              :detail="stats.money.detail"
              directionReverse></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <card class="h-100"
              :title="stats.robot.title"
              :values="stats.robot.values"
              :percentage="stats.robot.percentage"
              :iconClass="stats.robot.iconClass"
              :iconBackground="stats.robot.iconBackground"
              :detail="stats.robot.detail"
              directionReverse></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <card class="h-100"
              :title="stats.parrainages.title"
              :value="stats.parrainages.value"
              :percentage="stats.parrainages.percentage"
              :iconClass="stats.parrainages.iconClass"
              :iconBackground="stats.parrainages.iconBackground"
              :detail="stats.parrainages.detail"
              directionReverse></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12 mb-4">
            <card class="h-100"
              :title="stats.clients.title"
              :value="stats.clients.value"
              :percentage="stats.clients.percentage"
              :iconClass="stats.clients.iconClass"
              :iconBackground="stats.clients.iconBackground"
              :percentageColor="stats.clients.percentageColor"
              :detail="stats.clients.detail"
              directionReverse></card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <carousel />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loading-container">
    <p>Loading...</p>
    <!-- Vous pouvez remplacer cette ligne par un spinner de votre choix -->
  </div>
</template>



<script>
import Card from "@/examples/Cards/CardDashboard.vue";
// import CategoriesCard from "./components/CategoriesCard.vue";
import apiClient from "@/apiService.js";
import Carousel from "./components/Carousel.vue";

export default {
  name: "dashboard-default",
  data() {
    return {
      isLoading: true,
      stats: {
        money: {
          title: "Solde du compte",
          values: [],
          percentage: "",
          iconClass: "ni ni-money-coins",
          iconBackground: "bg-gradient-warning",
          detail: "Solde total du compte",
        },
        robot: {
          title: "Status Robot",
          value: [],
          percentage: "",
          iconClass: "ni ni-cart",
          detail: "Merci de contacter le support en cas de problème",
          iconBackground: "bg-gradient-primary",
        },
        parrainages: {
          title: "Clients",
          value: "",
          percentage: "",
          iconClass: "ni ni-world",
          iconBackground: "bg-gradient-danger",
          detail: "Utilisateurs parrainés (directs et indirects)",
        },
        clients: {
          title: "Clients actifs",
          value: "",
          percentage: "",
          iconClass: "ni ni-paper-diploma",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-success",
          detail: "Utilisateurs actifs parrainés (directs et indirects)"
        },
      },
    };
  },
  components: {
    Card,
    Carousel,
    // CategoriesCard,
  },
  methods: {
    async getDashboard() {
      try {
        const response = await apiClient.get("/users/getDashboard");
        if (response.status === 200) {

          const moneyValues = [];
          const robotValues = [];

          // On récupère chaque account
          for (let i = 0; i < response.data.accounts.length; i++) {
            if (response.data.accounts[i].strategy && response.data.accounts[i].accountBalance !== null && response.data.accounts[i].status !== null) {
              moneyValues.push({
                strategy: response.data.accounts[i].strategy,
                amount: response.data.accounts[i].accountBalance
              });
              robotValues.push({
                strategy: response.data.accounts[i].strategy,
                amount: response.data.accounts[i].status
              });
            }
          }

          // Si les tableaux sont vides, on ajoute un objet vide avec inactif en status et 0 en amount
          if (moneyValues.length === 0) {
            moneyValues.push({
              strategy: "0",
            });
            robotValues.push({
              strategy: "Inactif",
            });
          }
          if (moneyValues.length === 2) {
            this.stats.money.title = "Solde des comptes";
            this.stats.robot.title = "Status Robots";
            this.stats.money.detail = "Solde total des comptes";

          }
          this.stats.money.values = moneyValues;
          this.stats.robot.values = robotValues;
          this.stats.parrainages.value = response.data.referralsCount;
          this.stats.clients.value = response.data.activeNetworkUsers;
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getDashboard();
  },
  beforeMount() {
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
  text-align: center;
}

</style>