<template>
  <main>
    <div class="container-fluid">
      <div class="page-header min-height-300" style="
          background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
          margin-right: -24px;
          margin-left: -34%;
        ">
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body" style="width: auto;">
              <div>
                <button class="btn mb-0 btn-success btn-sm" @click="goToUsersList()">Retour aux users</button>
                <p class="text-uppercase text-sm mt-4">USER ID N° {{ user.id }} - {{ user.pseudo }}</p>
                <div class="row">
                  <div class="col-md-6 d-flex align-items-end">
                    <div class="col-md-12 px-0">
                      <label for="pseudo-input" class="form-control-label">Pseudo</label>
                      <input id="pseudo-input" class="form-control form-group mb-0" type="text" :disabled="isDisabled" v-model="user.pseudo" />
                    </div>
                  </div>
                  <div class="col-md-6 d-flex align-items-end">
                    <button class="btn mb-0 btn-success btn-sm mr-3" @click="editPseudo()">Modifier</button>
                    <button class="btn mb-0 btn-success btn-sm" @click="updateUserPseudo()">Mettre à jour</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
// import router from "@/router/index.js";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "userDetail",
  data() {
    return {
      showMenu: false,
      isDisabled: true, 
      user: {
        pseudo: "",
        email: "",
        password: "",
        etat: "",
        id: "",
      },
    };
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  methods: {
    async getUserDetail() {
      try {
        const response = await apiClient.get("/users/getUserDetail");
        if (response.status === 200) {
          this.user = response.data;
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
   
    goToUserDetail(userId) {
      this.$router.push({ name: 'UserDetail', query: { userid: userId } });
    },
    createTicketToUser(userId) {
      this.$router.push({ name: 'AdminTicketUser', query: { userid: userId } });
    },
    goToUsersList() {
      this.$router.push({ name: 'AdminUsers'})
    },
    editPseudo() {
      this.isDisabled = !this.isDisabled;
    },
    async updateUserPseudo() {
      try {
        const response = await apiClient.put("/admin/users/updateUserPseudo", {
          pseudo: this.user.pseudo,
          id: this.user.id,
        });
        if (response.status === 200) {
          this.toast.success("Pseudo modifié avec succès !");
          this.getUserTicket();
        }
      } catch (error) {
        this.toast.error(error.response.data.error);
      }
    },
   
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
    this.getUserDetail();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
<style>
.table thead th {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.btn-no-pointer {
        cursor: default !important;
    }
</style>