import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import AdminDashboard from "../views/AdminDashboard.vue";
import Network from "../views/Network.vue";
import Billing from "../views/Billing.vue";
import ProfitSharing from "../views/ProfitSharing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import AdminUsers from "../views/AdminUsers.vue";
import UserDetail from "../views/UserDetail.vue";
import Support from "../views/Support.vue";
import SupportDetail from "../views/SupportDetail.vue";
import TutoBot from "../views/TutoBot.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import MyRobot from "../views/MyRobot.vue";
import Subscription from "../views/Subscription.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Home from "../views/Home.vue";
import Tutorial from "../views/Tutorial.vue";
import AdminLogs from "../views/AdminLogs.vue";
// import PageNotFound from "../views/PageNotFound.vue";
import apiClient from "@/apiService.js";
import { useToast } from "vue-toastification";
import store from "../store/index.js";
import Maintenance from "../views/Maintenance.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    component: Tutorial,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/admin-dashboard",
    name: "Admin-Dashboard",
    component: AdminDashboard,
    meta: { requiresAdmin: true }, // Ajout de la métadonnée pour les routes admin
  },
  {
    path: "/network",
    name: "Network",
    component: Network,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/profitSharing",
    name: "ProfitSharing",
    component: ProfitSharing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/userdetail",
    name: "UserDetail",
    component: UserDetail,
  },
  {
    path: "/admin-users",
    name: "AdminUsers",
    component: AdminUsers,
    meta: { requiresAdmin: true },
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
  },
  {
    path: "/supportDetail",
    name: "SupportDetail",
    component: SupportDetail,
  },
  {
    path: "/tutoBot",
    name: "TutoBot",
    component: TutoBot,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/my-robot",
    name: "MyRobot",
    component: MyRobot,
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    // component: PageNotFound // Le composant à afficher (par exemple, une page d'erreur personnalisée)
    redirect: "/",
  },
  {
    path: "/logout",
    name: "Logout",
    // Pas de composant nécessaire, on utilise une méthode pour gérer la déconnexion
    beforeEnter: () => {
      // Logique de déconnexion
      logoutUser();
    },
  },
  {
    path: "/admin-logs",
    name: "AdminLogs",
    component: AdminLogs,
    meta: { requiresAdmin: true },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

const toast = useToast();

// Vérification de l'authentification
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/signin",
    "/signup",
    "/reset-password",
    "/",
    "/tutorial",
    "/tutoBot",
    "/maintenance",
  ];
  const authRequired = !publicPages.includes(to.path);
  const adminRequired = to.matched.some((record) => record.meta.requiresAdmin);

  if (authRequired) {
    apiClient
      .get("/auth/checkToken")
      .then((response) => {
        const isAdmin = response.data.roleId === 1;
        store.commit("setIsAdmin", isAdmin);
        if (!adminRequired || (adminRequired && isAdmin)) {
          next();
        } else {
          toast.error("Accès refusé. Vous n’avez pas les droits d’administrateur.");
          next(from.path);
        }
      })
      .catch(() => {
        toast.error("Vous devez être connecté pour accéder à cette page");
        next("/signin");
      });
  } else {
    next();
  }
});

function logoutUser() {
  // Envoyer une requête au serveur pour supprimer le token
  apiClient.get("/auth/logout");

  // Afficher un message de déconnexion
  toast.info("Déconnexion réussie");

  // Rediriger vers la page de connexion
  router.push("/signin");
}

export default router;
