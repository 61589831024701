import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// import AOS from 'aos'; // Import AOS
// import 'aos/dist/aos.css'; // Import AOS CSS

// import { checkApiStatus } from '@/apiService.js';

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
// checkApiStatus();

const options = {
    position: "top-right",
    timeout: 4000,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
    maxToasts: 3,
};
appInstance.use(Toast, options);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");

// AOS.init(); // Initialize AOS after mounting the app
