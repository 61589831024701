<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
        <network-table />
      </div>
    </div>
    <!-- <div class="mt-4 row">
      <div class="col-12">
        <projects-table />
      </div> 
    </div> -->
  </div>
</template>

<script>
import NetworkTable from "./components/NetworkTable.vue";
// import ProjectsTable from "./components/ProjectsTable.vue";

export default {
  name: "tables",
  components: {
    NetworkTable,
    // ProjectsTable
  },
  data() {
    return {
     
    };
  },
  beforeMount() {
      this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>
